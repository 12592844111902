import {
    SEND_CONTACT,
    SEND_CONTACT_ERROR
} from '../actions/types';

const initialState ={
    fullName:null,
    email:null,
    phone:null,
    message:null,
    error:null
}

export default function(state = initialState, action){
    switch(action.type){

        case SEND_CONTACT:
            return{
                ...state,
                fullName:action.payload.fullName,
                email:action.payload.email,
                message:action.payload.message
            }

        case SEND_CONTACT_ERROR:
            return{
                ...state,
                error:action.payload
            }

        default:
            return state
    }
}