import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContactForm from "./ContactForm";
import Banner from "./Banner";
import { motion } from "framer-motion";
import organization from "../../actions/organization";

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const About = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const getOrganization = () => dispatch(organization.get());
    getOrganization();
  }, []);
  const description = useSelector((state) => state.organization.description);
  const name = useSelector((state) => state.organization.image);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition}
      className="main"
    >
      <Banner />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 main-content">
            <div className="entry-content">
              <div className="row">
                <div className="col-lg-6">
                  <div className="text-center mb-4">
                  {name ? <img className="img-fluid" style={{maxWidth: '300px', margin: 'auto'}} src={name}></img> : null}
                  </div>
                  <blockquote className="about text-center py-4 py-md-0">
                    <p className="mb-0">{description}</p>
                  </blockquote>
                </div>
                <div className="col-lg-6">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
