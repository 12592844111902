/**USERS TYPES */
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const AUTHENTICATED_USER = 'AUTHENTICATED_USER';
export const LOG_OUT = 'LOG_OUT';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';

/*ORGANIZATION*/
export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const EDIT_ORGANIZATION_SUCCESS = 'EDIT_ORGANIZATION_SUCCESS';
export const EDIT_ORGANIZATION_ERROR = 'EDIT_ORGANIZATION_ERROR';

/*CONTACT FORM*/
export const SEND_CONTACT ='SEND_CONTACT';
export const SEND_CONTACT_ERROR ='SEND_CONTACT_ERROR';

/**NEWS ENTRIES */
export const SHOW_NEWS = 'SHOW_NEWS';
export const START_LOADING_NEWS = 'START_LOADING_NEWS';
export const LOADING_NEWS_SUCCESS= 'LOADING_NEWS_SUCCESS';
export const LOADING_NEWS_ERROR='LOADING_NEWS_ERROR';


/**EVENTS ENTRIES */
export const START_LOADING_EVENTS = 'START_LOADING_EVENTS';
export const LOADING_EVENTS_SUCCESS= 'LOADING_EVENTS_SUCCESS';
export const LOADING_EVENTS_ERROR='LOADING_EVENTS_ERROR';

// Entries
export const START_LOADING_ENTRIES = 'START_LOADING_ENTRIES'; 
export const LOADING_ENTRIES_SUCCESS = 'LOADING_ENTRIES_SUCCESS';
export const LOADING_ENTRIES_ERROR = 'LOADING_ENTRIES_ERROR';
export const DELETE_ENTRIES_SUCCESS = 'DELETE_ENTRIES_SUCCESS';
export const DELETE_ENTRIES_ERROR = 'DELETE_ENTRIES_ERROR'; 

/*  TESTIMONIES */
export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';
export const DELETE_TESTIMONY_SUCCESS = 'DELETE_TESTIMONY_SUCCESS';
export const DELETE_TESTIMONY_ERROR = 'DELETE_TESTIMONY_ERROR';
/* Contribution */
export const GET_CONTRIBUTIONS = 'GET_CONTRIBUTIONS';

/*  SLIDES */
export const GET_ALL_SLIDES = 'GET_SLIDES';

