import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Esto es porque sino no alterna el formulario, los 3 son iguales
import SlideForm from './Home/SlideForm'
import SlideForm2 from './Home/SlideForm2'
import SlideForm3 from './Home/SlideForm3'
import slidesAction from '../../../actions/slidesAction'
import { useHistory } from 'react-router-dom'
import { motion } from "framer-motion";

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const EditHome = () => {
  const state = useSelector(state => state.slideReducer)
  const [slides, setSlides] = useState(state.slides)
  const [pagina, setPagina] = useState('1')
  const history = useHistory();
  const dispatch = useDispatch()
  
  const onFinish = async () => {
    history.push('/escritorio/datos-home');
    dispatch(await slidesAction.getAll())
  }
  const getSlide = (order) => {
    const slide = slides.find(o => o.order == order)
    if (!slide) {
      return {image: '',
      order,
      bienvenida: '',
      text: '',
       }
    } else {
      return slide
    }
  }
  
  const selector = () => {
    return <div className="tab-menu tab-row">
    <ul id="bx-pager" className="menu-category">
      {/* <li className={pagina == 'home' ? "mc-option active" : "mc-option"} data-tab="pp0" onClick={() => setPagina('home')}><a>Welcome Text</a></li> */}
      <li className={pagina == '1' ? "mc-option active" : "mc-option"} data-tab="pp1" onClick={() => {setPagina('1')}}><a>Slide 1</a></li>
      <li className={pagina == '2' ? "mc-option active" : "mc-option"} data-tab="pp2" onClick={() => setPagina('2')}><a>Slide 2</a></li>
      <li className={pagina == '3' ? "mc-option active" : "mc-option"} data-tab="pp3"  onClick={() => setPagina('3')}><a>Slide 3</a></li>
    </ul>
  </div>
  }
  console.log(pagina)
  const changePage = () => {
    switch (pagina) {
      case 'home':
        return <h1>Home</h1>
      case '1':
        return <SlideForm slide={getSlide(1) } onFinish={() => onFinish()} />
      case '2': 
      return <SlideForm2 slide={getSlide(2)} onFinish={() => onFinish()} />
      case '3': 
      return <SlideForm3 slide={getSlide(3)} onFinish={() => onFinish()} />
      default:
        return <h1>Bug!</h1>
    }
  }
  return (
  <motion.div
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{ opacity: 0 }}
  transition={transition}
  className="account-wrapper"
>
     <div className="account-content"> 
    {selector()}
    {changePage()}
    </div>
  </motion.div>
  )

}
export default EditHome