import React from 'react';

const Donations = () => {
  return (
    <div>
      <h1>Donaciones/Colectas</h1>
      <p>
        Llevamos a cabo durante el año cuatro{' '}
        <b>colectas de alimentos, de elementos de higiene y de ropa</b>. Tenemos
        una metodología específica, ya que necesitamos alimentos variados para
        lograr una nutrición saludable en la familia. Juntamos una variedad de
        54 productos, que se asignan particularmente a cada inscripto. En la
        colecta de ropa damos prioridad al calzado e indumentaria básica,
        realizamos filtros específicos y priorizamos los talles de cada miembro
        de la familia.
      </p>
      <div style={{ maxHeight: '500px', overflow: 'hidden' }}>
        <img
          src={process.env.PUBLIC_URL + '/images/colectas.jpg'}
          alt="Imagen donaciones"
        />
      </div>
    </div>
  );
};

export default Donations;
