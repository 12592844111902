const pageTextsConstants = {
	events: {
		title: 'Eventos',
		subTitle: 'Conocé todos los eventos que organizamos'
	},
	news: {
		title: 'Novedades',
		subTitle: 'Conocé todas las novedades de nuestra organización'
	}
};

export default pageTextsConstants;
