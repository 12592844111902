import React from 'react'
import _ from 'lodash'
import HelpItem from './HelpItem'
const HowToHelp = () => {
  
  const content = [{
      id: 1, 
      title: 'Donaciones/Colectas', 
      icon: 'fa fa-money', 
      description: 'Llevamos a cabo durante el año cuatro colectas de alimentos, de elementos de higiene y de ropa.'
    },
    {
      id: 2, 
      title: 'Regalos especiales', 
      icon: 'fa fa-gift', 
      description: 'Contamos con un proyecto que convoca regalos personalizados para todos/as los/as niños/as asistidos/as, y sus hermanitos/as.'
    },
    {
      id: 3, 
      title: 'Madrinaje/Padrinaje', 
      icon: 'fa fa-users', 
      description: 'Apuntamos a desarrollar madrinas y padrinos que dediquen tiempo al acompañamiento de las familias integralmente'
    }
  ]
    const listContent = () => {
      return _.map(content, (item) => {
        return <HelpItem id={item.id} title={item.title} icon={item.icon} description={item.description} />
      })
    }
   return <div className="how-it-work">
   <div className="container">
     <h2 className="title">¿Cómo nos podés ayudar?</h2>
     <div className="description">Tenemos diversas maneras para que puedas contribuir con nosotros</div>
     <div className="row">
       {listContent()}
     </div>
   </div>
 </div>
}

export default HowToHelp