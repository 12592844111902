import URLs from './urls'
import baseApi from './base'

const sendRecovery = async (data) => {
  return await baseApi.postMethod(URLs.RECOVERY_REQUEST, data)
}

export default {
  sendRecovery
}
