import {
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    EDIT_PROFILE,
    LOG_OUT,
    AUTHENTICATED_USER,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_ERROR
} from '../actions/types';

const initialState = {
    isLogged: false,
    error: null,
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    roleId: null
}

export default function (state = initialState, action) {

    switch (action.type) {
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLogged: true,
                id: action.payload.id,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                roleId: action.payload.roleId
            }


        case LOG_OUT:
             return{
                ...state,
                isLogged: false,
                email: '',
                firstName: '',
                lastName: '',
                id: '',
                roleId: null
             }
        case EDIT_PROFILE:
            return {
                ...state,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName
            }

        case AUTHENTICATED_USER:
            return {
                ...state,
                isLogged: true,
                email: action.payload.email,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                id: action.payload.id,
                roleId: action.payload.roleId
            }

        case DELETE_ACCOUNT_ERROR:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isLogged: false,
                email: '',
                firstName: '',
                lastName: '',
                id: '',
                roleId: null
            }
        default:
            return state;
    }
}