import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import ImageField from "./ImageField";
import CKEditor from "ckeditor4-react";
import testimonyApiService from '../../../api/testimony';
import Swal from 'sweetalert2';
import Spinner from '../../spinner/Spinner';

const CreateTestimony = ({ history }) => {
  //States
  const [newTestimony, setNewTestimony] = useState({
    name: '',
    content: '',
  });
  const { name, content } = newTestimony;
  const [file, setFile] = useState('');
  const [error, setError] = useState(false);
  const [load, loadSpinner] = useState(false);

  const handleChange = (e) => {
    setNewTestimony({
      ...newTestimony,
      [e.target.name]: e.target.value,
    });
  };

  const readFile = (e) => {
    setFile(e.target.files[0]);
  };

  const mostrarSpinner = ()=>{
    loadSpinner(true);

    setTimeout(()=>{
        loadSpinner(false);
    }, 2000);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    //Validate fields
    if (
      name.trim() === '' ||
      content.trim() === '' ||
      !file
    ) {
      setError(true);
    }
    setTimeout(() => {
      setError(false);
    }, 3000);

    if(!error){
      mostrarSpinner();
    }
    //Formdata created
    const formData = new FormData();
    formData.append("name", newTestimony.name);
    formData.append("content", newTestimony.content);
    formData.append("media", file);

    try {
      
      setTimeout( async () => {
        await testimonyApiService.post(formData);
      Swal.fire(
        'Creado',
        'Testimonio creado correctamente',
        'success'
      )
      history.push('/escritorio/administrar-testimonios');
      }, 2000);

    } catch (error) {
      console.log(error);
    }
  };

   //Mostra spinner 
   const componente = (load) ? <Spinner /> :  null


  return (
    <div className="start-form">
      <form onSubmit={handleSubmit}>
        {/*<!-- Imagen -->*/}
        <ImageField file={file} onChange={readFile} />
        {error ? (
          <small className="form-text alert-danger text-center">
            Todos los campos son obligatorios
          </small>
        ) : null}
        {/*<!-- Titulo -->*/}
        <div className="field">
          <label htmlFor="name">
            <span className="font-weight-bold text-dark">Titulo</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
          />
          {error ? (
            <small className="form-text alert-danger text-center">
              Todos los campos son obligatorios
            </small>
          ) : null}
        </div>
        {/*<!-- Contenido -->*/}
        <div className="field">
          <label htmlFor="content">
            <span className="font-weight-bold text-dark">Contenido</span>
          </label>
          <CKEditor
            data={content}
            onChange={(e) => {
              setNewTestimony({
                ...newTestimony,
                content: e.editor.getData(),
              });
            }}
          />
          {error ? (
            <small className="form-text alert-danger text-center">
              Todos los campos son obligatorios
            </small>
          ) : null}
        </div>
        <div className="d-flex flex-column flex-md-row">
          <input type="submit" className="btn btn-primary" value="Crear" />
          {componente}
        </div>
      </form>
    </div>
  );
};

export default withRouter(CreateTestimony);