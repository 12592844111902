import React from "react";
import PageTitle from './PageTitle';
import FormRegister from './FormRegister';
const Register = () => {
  return (
    <main id="main" className="site-main">

      <PageTitle/>
      {/*<!-- .page-title -->*/}

      <div className="container">
        <div className="main-content">
          <FormRegister/>
        </div>
      </div>
    </main>
  );
};

export default Register;
