import React from 'react';

const LoginBanner = ({ title, subTitle }) => {
  return (
    <div
      className="page-title"
      style={{
        background: `url(${process.env.PUBLIC_URL}/images/ONG5.jpg)`,
        backgroundPosition: '50% 55%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <div className="container">
        <div>
          <h1>Login</h1>
        </div>
      </div>
    </div>
  );
};

export default LoginBanner;
