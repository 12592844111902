import React from 'react';
import { connect } from 'react-redux';
import ListTestimonies from './ListTestimonies';
import CreateTestimony from './CreateTestimony';
import EditTestimony  from './EditTestimony';
import { motion } from "framer-motion";
import testimonials from '../../../actions/testimonyActions';

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const AdminTestimonies = () => {
  const [showCreate, setShowCreate] = React.useState(false);
  const showCreateForm = (val) => {
    setShowCreate(val)
  }
  const [showEdit, setShowEdit] = React.useState(false);
  const [id, setId] = React.useState(null);
    const showEditForm = (val) => {
    setShowEdit(val.show)
    setId(val.id)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition}
      className="account-wrapper"
    >
      {showCreate ?
        <CreateTestimony showCreateForm={showCreateForm} />
        :
        !showEdit ?
        <ListTestimonies showEditForm={showEditForm} showCreateForm={showCreateForm} />
        : null
      }
      {showEdit ?
        <EditTestimony showEditForm={showEditForm} id={id} />
        : null
      }
    </motion.div>
  )
};

const mapStateToProps = (state) => {
  return {
    entries: state.testimonyReducer
  }
}

export default connect(mapStateToProps, testimonials.getAll())(AdminTestimonies);