import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PencilIcon, TrashIcon } from '@primer/octicons-react';
import testimonyActions from '../../../actions/testimonyActions';
import Swal from 'sweetalert2';
import Moment from 'moment';

const ListTestimonies = (props) => {
    const testimonials = useSelector(state => state.testimonyReducer.testimonials);
    const dispatch = useDispatch();
    const createTestimony = () => {
        props.showCreateForm(true)
    }
    const deleteTestimony = (id) => {
        Swal.fire({
            title: '¿Estas Seguro?',
            text: "Un testimonio eliminado, no se puede recuperar!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si,eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(testimonyActions.remove(id))
            }
        })
    }
    const editTestimony = (id) => {
        props.showEditForm({
            show: true,
            id: id
        })
    }
    return (
        <div className="account-content backed-campaigns account-table">
            <div className="text-center mt-2">
                <button
                    type="button"
                    className="btn btn-primary mb-4 mb-md-auto"
                    style={{ cursor: 'pointer' }}
                    onClick={() => createTestimony()}
                >CREAR TESTIMONIO</button>
            </div>
            <h3 className="account-title mt-2"> Lista de Testimonios</h3>
            <div className="account-main">
                <table>
                    <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Nombre</th>
                            <th className="text-center">Fecha de Creación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            testimonials.map((testimony) => {
                                return (
                                    <tr className="text-center" key={testimony.id}>
                                        <td className="text-center">{testimony.id}</td>
                                        <td className="text-center">{testimony.name}</td>
                                        <td className="text-center">{Moment(testimony.createdAt).format("DD/MM/YYYY")}</td>
                                        <td className="btn-group">
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => editTestimony(testimony.id)}
                                            >
                                                <PencilIcon size={16} />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-danger ml-1"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => deleteTestimony(testimony.id)}
                                            >
                                                <TrashIcon size={16} />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListTestimonies;