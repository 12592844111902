import React from "react";
const ImageField = ({ file, onChange }) => {
  return (
    <div className="field">
      <span className="font-weight-bold text-dark">Agregar Imagen</span>
      <label htmlFor="uploadfile">
      <div className="list-upload">
        <div className="file-upload w-100">
          <div className="upload-bg">
            <div id="myfileupload">
              <input
                type="file"
                id="uploadfile"
                name="file"
                onChange={onChange}
                accept="image/*"
              />
            </div>
            <div id="thumbbox">
              <img
                src="images/assets/logo.png"
                height="695"
                width="460"
                alt="Thumb image"
                id="thumbimage"
              />
              <a className="removeimg"></a>
            </div>
            <div id="boxchoice">
              {file ? (
                <a className="choicefile">
                  <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                  Imagen: {file.name}
                </a>
              ) : (
                <a className="choicefile">
                  <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                  Upload Image
                </a>
              )}
              <p></p>
            </div>
            <label className="filename"></label>
          </div>
        </div>
      </div>
      </label>
    </div>
  );
};
export default ImageField;
