import React, { useState } from 'react';
//import Joi from 'joi';
import ImageField from './ImageField';
//import { ValidationMessage } from './EditValidationMessage';
import {useDispatch,useSelector} from 'react-redux';
import organizationActions from '../../../actions/organization';
import Spinner from '../../spinner/Spinner';


export const EditOrgForm = ({history}) => {

  const dispatch = useDispatch();

  const edit = (data) => dispatch(organizationActions.editAction(data));

  const getName = useSelector(state => state.organization.name);
  const getImage = useSelector(state => state.organization.image);

  const [urlImage,setUrlImage] = useState(getImage);
  const [name, setName] = useState(getName);
  const [image, setImage] = useState('');
  const [error,setError] = useState(false);
  const [load, loadSpinner] = useState(false);

  const readFile = (e) => {
    setImage(e.target.files[0]);
  };

  const onNameChanged = (e) => {
    setName(e.target.value);
  };

  const mostrarSpinner = ()=>{
      loadSpinner(true);

      setTimeout(()=>{
          loadSpinner(false);
      }, 2000);
  };

    //Mostra spinner 
const componente = (load) ? <Spinner /> :  null

  
  const onEditClicked = (e) => {
    e.preventDefault();

    //validar formulario
    if(name.trim() === ''){
      setError(true);
    }
    setTimeout(() => {
      setError(false);
    }, 3000);
   
    mostrarSpinner()

    const formData = new FormData();
    formData.append("media", image);
    formData.append("name", name);
  
    edit(formData)

    setTimeout(() => {
      history.push('/');
    }, 1000);


  };

  return (
    <>
      <h2>Editar Datos de la Organización</h2>
      
      <div className="start-form">
      <form>
      {!image ?
          <div className="field">
            <label htmlFor="title">
              <span className="font-weight-bold text-dark">Imagen Actual</span>
              <img src={urlImage} alt="imagen del logo" />
            </label>
          </div>
          : null
        }
        {/* <!-- Imagen --> */}
        <ImageField image={image} onChange={readFile} />

        <div className="field">
          <input
            type="text"
            name="name"
            value={name}
            placeholder="Nombre de la organización"
            onChange={onNameChanged}
          />
        </div>
        {error ? (
          <small className="form-text alert-danger text-center">
            El nombre no puede quedar vacio
          </small>
        ) : null}
{componente}
        <div className="inline clearfix">
          <button className="btn btn-primary" onClick={onEditClicked}>
            Editar
          </button>
        </div>
      </form>
      </div>
    </>
  );
};
