import React from 'react';
import { Link } from 'react-router-dom';
//import newsActions from '../../actions/newsActions';

const Card = ({ item, setNewsDetail }) => {
    const { id, title, content, image } = item;

    return (
        <div className="col-lg-4 col-sm-6">
            <div className="campaign-item mb-4">
                <img src={image} alt="foto" />
                <div className="campaign-box">
                    <h3>{title}</h3>
                    <div className="campaign-description">
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                    <Link
                        className="btn btn-info"
                        to={`/novedad-detalle/${id}`}
                        onClick={() => setNewsDetail(id)}
                    >
                        Leer Mas
                    </Link>
                </div>
            </div>
        </div>
    );
}


export default Card;