import { GET_ALL_SLIDES } from './types/index';
import slideApi from '../api/slides';

const getAll = () => async dispatch => { 
    try {
        const slides = await slideApi.getAllSlides()
        dispatch({ type: GET_ALL_SLIDES, payload: slides })
        return { type: GET_ALL_SLIDES, payload: slides }
    } catch (error) {
        
    }
}

export default {
    getAll
}