import ROUTES from './urls';
import base from './base';

const get = async () => {
	const result = await base.getMethod(ROUTES.CONTRIBUTORS);
	return result
}


export default {
    get
}