import React, { useState } from "react";
import { useHistory, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ImageField from "./ImageField";
import CKEditor from "ckeditor4-react";
import testimonyApiService from '../../../api/testimony';
import Swal from 'sweetalert2';
import Spinner from '../../spinner/Spinner';

const EditTestimony = (props) => {
  const history = useHistory();
  const testimonials = useSelector(state => state.testimonyReducer.testimonials);
  const testimonyToUpdate = testimonials.find(key => key.id === props.id)
  
  //States
  const [name, setName] = useState(testimonyToUpdate.name);
  const [content, setContent] = useState(testimonyToUpdate.content);
  const [file, setFile] = useState('');
  const [error, setError] = useState(false);
  const [load, loadSpinner] = useState(false);

  const readFile = (e) => {
    setFile(e.target.files[0]);
  };

  const mostrarSpinner = ()=>{
    loadSpinner(true);

    setTimeout(()=>{
        loadSpinner(false);
    }, 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //Validate fields
    if (
      name.trim() === '' ||
      content.trim() === ''
    ) {
      setError(true);
    }
    setTimeout(() => {
      setError(false);
    }, 3000);

    if(!error){
      mostrarSpinner();
    }


    //Formdata created
    const formData = new FormData();
    formData.append("name", name);
    formData.append("content", content);
    formData.append("media", file);

    try {
     
      setTimeout( async () => {
        await testimonyApiService.update(props.id, formData);
        Swal.fire(
          'Editado',
          'Testimonio editado correctamente',
          'success'
        )
        history.push('/escritorio/administrar-testimonios');
      }, 2000);

    } catch (error) {
      console.log(error);
      setTimeout(() => {
        Swal.fire(
          'ERROR',
          'Hubo un problema, verifica que todos los campos esten completados',
          'error'
        )
       }, 2000);
    }
  };
  
      //Mostra spinner 
      const componente = (load) ? <Spinner /> :  null

  return (
    <div className="start-form">
      <form onSubmit={handleSubmit}>
        {/* <!-- Imagen --> */}
        {!file ?
          <div className="field">
            <label htmlFor="name">
              <span className="font-weight-bold text-dark">Imagen Actual</span>
              <img src={testimonyToUpdate.image} alt="imagen de entrada" />
            </label>
          </div>
          : null
        }

        <ImageField file={file} onChange={readFile} />

        {error ? (
          <small className="form-text alert-danger text-center">
            Todos los campos son obligatorios
          </small>
        ) : null}

        {/*<!-- Titulo -->*/}
        <div className="field">
          <label htmlFor="name">
            <span className="font-weight-bold text-dark">Titulo</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          {error ? (
            <small className="form-text alert-danger text-center">
              Todos los campos son obligatorios
            </small>
          ) : null}
        </div>

        {/*<!-- Contenido -->*/}
        <div className="field">
          <label htmlFor="content">
            <span className="font-weight-bold text-dark">Contenido</span>
          </label>
          <CKEditor
            data={testimonyToUpdate.content}
            onChange={(e) => { setContent(e.editor.getData()) }}
          />
          {error ? (
            <small className="form-text alert-danger text-center">
              Todos los campos son obligatorios
            </small>
          ) : null}
        </div>        
        <div className="d-flex flex-column flex-md-row">
          <input type="submit" className="btn btn-primary" value="Editar" />
          {componente}
        </div>
      </form>
    </div>
  );
};

export default withRouter(EditTestimony);