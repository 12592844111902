import React from 'react';
import Moment from 'moment';

const CardExample = ({ item }) => {
	const { content, createdAt, image, title } = item;

	const preview = content.slice(0, (content.length / 8));

	return (
		<article className="post h-50">
			<img src={image} alt="" className="img-entry" />
			<div className="post-info">
				<h3 className="post-title">
					{title}
				</h3>
				<ul className="post-meta">
					<li>
						<i className="fa fa-calendar-check-o" aria-hidden="true"></i>
						<span>{Moment(createdAt).format("DD/MM/YYYY")}</span>
					</li>
				</ul>
				<div className="post-desc">
					<p>
						<div dangerouslySetInnerHTML={{ __html: `${preview}...` }} />
					</p>
				</div>

				<button className="btn btn-info mt-4">
					Leer Mas
                </button>
			</div>
		</article>
	);
};

export default CardExample;
