import Axios from 'axios';
import ROUTES from './urls';
import JWTStorage from '../localstorage/jwt';
import base from './base';

//ToDo: las excepciones/errores de los metodos no estan manejadas aca,
// En caso de manejar un error de authenticacion, puede ser 406, 
// Deberia limpiarse el JWT

const logInMethod = async (credentials) => {
  const response = await Axios.post(ROUTES.LOG_IN, credentials);
  JWTStorage.setJWT(response.data.jwt);
  // return response.data
}
const myProfileMethod = async () => {
  const response = await base.getMethodAuthenticated(ROUTES.MY_PROFILE)
  return response
}

const registerMethod = async (user) => {
  const response = await Axios.post(ROUTES.REGISTER, user);
  return response.data;
}

const authMethod = async () =>{
  const response = await base.getMethodAuthenticated(ROUTES.MY_PROFILE);
  return response;
}

const deleteMethod = async (id) =>{
  const response = await base.deleteUserMethod(ROUTES.USERS)
  JWTStorage.clearJWT();
  return response;
}

const updateMethod = async (body) => {
  const response = await base.putMethodFormdataAuthenticated(ROUTES.USERS, body)
  return response
}

export default {
  logInMethod,
  registerMethod,
  myProfileMethod,
  authMethod,
  deleteMethod,
  updateMethod 
}
