import React from 'react';
import { useSelector } from 'react-redux';
import Card from './Card';

const ActivitiesList = ({setNewsDetail}) => {
    const news = useSelector(state => state.newsReducer.news);
    const lastThree = news.slice(0, 3);
    return (
        <div>
            <div className="campaign-content">
                <div className="row">
                    {
                        lastThree.map(item => (
                            <Card
                                key={item.id}
                                item={item}
                                setNewsDetail={setNewsDetail}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default ActivitiesList;