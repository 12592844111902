import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import organization from "../../actions/organization";

const WelcomeText = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const getWelcomeText = () => dispatch(organization.get());
    getWelcomeText();
  }, []);
  const name = useSelector((state) => state.organization.image);
  const welcomeText = useSelector((state) => state.organization.welcomeText);
  return (
    <>
      <div className="p-0 mt-md-4" style={{textAlign: 'center'}}>
        {name ? <img className="img-fluid" style={{maxWidth: '300px', margin: 'auto'}} src={name}></img> : null}
        <div className="description">
          {welcomeText ? <p className="wellcome">{welcomeText}</p> : null}
        </div>
      </div>
    </>
  );
};

export default WelcomeText;
