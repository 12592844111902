import React from "react";
import GenericScreenGrid from "./GenericScreenGrid";
import GenericScreenTitle from "./GenericScreenTitle";
//import PageNavigation from "./PageNavigation";
import pageTextsConstants from "./constants";
import { motion } from "framer-motion";

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const GenericScreen = (props) => {
  const urlPath = props.location.pathname;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition}
      className="blog-grid full-width"
    >
      <div id="wrapper">
        <main id="main" className="site-main">
          <GenericScreenTitle
            title={
              urlPath === '/novedades'
                ? pageTextsConstants.news.title
                : urlPath === '/eventos'
                ? pageTextsConstants.events.title
                : ''
            }
            subTitle={
              urlPath === '/novedades'
                ? pageTextsConstants.news.subTitle
                : urlPath === '/eventos'
                ? pageTextsConstants.events.subTitle
                : ''
            }
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-12 main-content">
                <GenericScreenGrid urlPath={urlPath} />
                
              </div>
            </div>
          </div>
        </main>
      </div>
    </motion.div>
  );
};

export default GenericScreen;
