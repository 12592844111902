import {combineReducers} from 'redux';
import authReducer from './authReducer';
import organization from './organizationReducer';
import newsReducer from './newsReducer';
import contactReducer from './contactReducer';
import eventsReducer from './eventsReducer';
import entriesReducer from './entries.Reducer';
import testimonyReducer from './testimonyReducer';
import contributionReducer from './contributionReducer';
import slideReducer from './slidesReducer';

export default combineReducers({
    authReducer,
    organization,
    contactReducer,
    newsReducer,
    eventsReducer,
    entriesReducer,
    testimonyReducer,
    contributionReducer,
    slideReducer
});