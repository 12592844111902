import React from 'react';
import { Switch } from 'react-router-dom';
import { EditOrgForm } from './organization/EditOrgForm';
import PrivateRoute from '../../routers/PrivateRoute';
import Profile from './profile/Profile';
import AdminEntries from './entries/AdminEntries';
// import { EditHomeForm } from './organization/EditHomeForm';
import EditHome from './organization/EditHome'
import AdminTestimonies from './testimony/AdminTestimonies';
import ListContributions from './contribution/ListContributions';

const AccountContentAdmin = () => {
  return (
    <div className="col-lg-9">
      <Switch>
        <PrivateRoute path="/escritorio/perfil" component={Profile} />
        <PrivateRoute path="/escritorio/datos-organizacion" component={EditOrgForm}/>
        <PrivateRoute path="/escritorio/contribuciones" component={ListContributions} /> 
        <PrivateRoute path="/escritorio/datos-home" component={EditHome} />        
        <PrivateRoute path="/escritorio/administrar-entradas" component={AdminEntries} />
        <PrivateRoute path="/escritorio/administrar-testimonios" component={AdminTestimonies} />
      </Switch>
    </div>
  );
};

export default AccountContentAdmin;
