import ROUTES from './urls';
import baseApi from './base';
 
const getAllSlides = async () => {
    const result = await baseApi.getMethod(ROUTES.SLIDES);
    return result.slides
}

const post = async(formdata) => {
    const result = await baseApi.postMethodFormdataAuthenticated(ROUTES.SLIDES, formdata)
    return result
  }

const put = async (formdata, id) => {
    const result = await baseApi.putMethodFormdataAuthenticated(ROUTES.SLIDES + '/' + id, formdata)
    return result
}

export default {
    getAllSlides,
    post,
    put
}
