import React from 'react';

const Gifts = () => {
  return (
    <div>
      <h1>Regalos Especiales</h1>
      <p>
        Contamos con un proyecto que convoca <b>regalos personalizados</b> para
        todos/as los/as niños/as asistidos/as, y sus hermanitos/as. Esto se
        realiza para fechas especiales: inicio de clases, día del niño/a, su
        cumpleaños y Navidad. Donde cada niño/a nos cuenta sus preferencias, y
        nosotros/as nos transformamos en un puente que convoca personas que
        quieran regalar eso especifico pedido por ellos/as.
      </p>
      <div style={{ maxHeight: '500px', overflow: 'hidden' }}>
        <img
          src={process.env.PUBLIC_URL + '/images/regalos.jpg'}
          alt="Imagen regalos"
        />
      </div>
    </div>
  );
};

export default Gifts;
