import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import About from './components/about/About';
import Help from './components/help/Help';
import Login from './components/login/Login';
import Recovery from './components/recovery/Recovery';
import Registro from './components/register/Register'
import GenericScreen from './components/generic/GenericScreen'
import Backoffice from './components/backoffice/Backoffice';
import PrivateRoute from './routers/PrivateRoute';
import PublicRoute from './routers/PublicRoute';
import DetailNovely from './components/news/DetailNovely';
import RecoverPassword from './components/recovery/RecuperarClave'
import { AnimatePresence } from "framer-motion";
import Chatbox from './components/chatbox/Chatbox'

//redux
import {Provider} from 'react-redux';
import store from './app/store';

console.log(store.getState());

class App extends Component {
  render() {
    return (
      <Router>
        <Provider store={store}>
        <div>
          <Header />
          <AnimatePresence exitBeforeEnter>
            <Switch>
              <PublicRoute path="/" component={Home} exact={true} />
              <PublicRoute path="/nosotros" component={About} />
              <PublicRoute path="/ayuda" component={Help} />
              <PublicRoute path="/eventos" component={GenericScreen} />
              <PublicRoute path="/novedades" component={GenericScreen} />
              <PublicRoute path="/recuperar_clave" component={RecoverPassword} />
              <PublicRoute  path="/novedad-detalle/:id" component={DetailNovely} />
              <PrivateRoute path="/escritorio" component={Backoffice} />
              <PublicRoute path="/login" component={Login} />
              <PublicRoute path="/recovery" component={Recovery} />
              <PublicRoute path="/registro" component={Registro} />
            </Switch>
          </AnimatePresence>
          <Footer />
       
        </div>
        </Provider>
      </Router>

    );
  }
}

export default App;
