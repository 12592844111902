import {
  GET_TESTIMONIALS,
  DELETE_TESTIMONY_SUCCESS,
  DELETE_TESTIMONY_ERROR
} from '../actions/types/'

const initialState = {
  testimonials: [],
  testimony: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TESTIMONIALS:
      return {
        ...state,
        testimonials: action.payload
      }
    case DELETE_TESTIMONY_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case DELETE_TESTIMONY_SUCCESS:
      state.testimonials = state.testimonials.filter(p => p.id !== action.payload)
      return state
    default:
      return state
  }
}