import React from 'react';
import {connect} from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({
    isLogged, 
    component: Component,
    ...rest
}) => (
    <Route {...rest} component={(props) => (
        isLogged ? (
            <div>
                <Component {...props} />
            </div>            
        ): (
            <Redirect to="/" />
        )
    )} />
)

const mapStatetoProps = (state) => ({
    isLogged: state.authReducer.isLogged
});

export default connect(mapStatetoProps)(PrivateRoute);