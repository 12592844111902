import React, { useState } from 'react';
import Joi from 'joi';
import Swal from 'sweetalert2';
import slidesApiService from '../../../../api/slides';
import ImageField from '../../entries/ImageField'
import Spinner from '../../../spinner/Spinner';
import { ValidationMessage } from '../EditValidationMessage';

const Slideform = ({slide, onFinish}) => {
  const [welcomeText, setWelcomText] = useState(slide.bienvenida);
  const [welcomeTextErrorMessage, setWelcomTextErrorMessage] = useState('');
  const [file, setFile] = useState(null)
  const [load, loadSpinner] = useState(false);
  const onWelcomTextChanged = (e) => {
    setWelcomText(e.target.value);
  };

  const mostrarSpinner = ()=>{
    loadSpinner(true);

    setTimeout(()=>{
        loadSpinner(false);
    }, 2000);
  };

  console.log('--------------')
  console.log(slide)
  const validateEditForm = () => {
    const schema = Joi.object().keys({
      welcomeText: Joi.string().min(20).required()
    });

    const validation = schema.validate({ welcomeText });
    if (validation.error) {
      const errorType = validation.error.details[0].context.label;
      if (errorType === 'welcomeText') {
        setWelcomTextErrorMessage('Ingresá un texto de más de 20 carácteres.');
      }
    }
    return validation.error;
  };

  const [newSlide, setNewSlide] = useState({
    bienvenida: slide.bienvenida,
    text: slide.text,
    order: slide.order,
    image: slide.image,
    id: slide.id
  });
  const {  bienvenida, text, order, image, id } = newSlide;
  console.log(newSlide)
  const handleChange = (e) => {
    setNewSlide({
      ...newSlide,
      [e.target.name]: e.target.value,
    });
  };
  // const handleChangeBienvenida = (text) => {
  //   setNewSlide({
  //     ...newSlide,
  //     []
  //   })
  // }

  const readFile = (e) => {
    setFile(e.target.files[0]);
  };

  const clearEditFormFields = () => {
    setWelcomText('');
  };

  const onEditClicked = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("bienvenida", newSlide.bienvenida);
    formData.append("text", newSlide.text);
    formData.append("media", file)
    formData.append("order", order)
    //formData.append("media", newSlide.media);
    //formData.append("secondImage", newSlide.secondImage);
    //formData.append("secondSlideText", newSlide.secondSlideText);
    //formData.append("thirdImage", newSlide.thirdImage);
   // formData.append("thirdSlideText", newSlide.thirdSlideText);
   mostrarSpinner();
    try {
      let message, status
      console.log(id)
      if (id == undefined) {
        await slidesApiService.post(formData); 
        message = 'El slide se ha creado'
        status= 'Creado'
      } else {
        await slidesApiService.put(formData, id)
        message = 'El Slide de fotos se modifico'
        status = "Editado"
      }
     
      setTimeout(() => {
        Swal.fire(
          status,
          message,
          'success'
        )
        console.log({ welcomeText });
        onFinish()
      const validationError = validateEditForm();
      if (!validationError) clearEditFormFields();
      }, 2000);
     
    } catch (error) {
      console.log(error);
     setTimeout(() => {
      Swal.fire(
        'ERROR',
        'Hubo un problema, verifica que todos los campos esten completados',
        'error'
      )
     }, 2000);
    }
  };

     //Mostra spinner 
     const componente = (load) ? <Spinner /> :  null

  console.log(id)
  return (
    <div className="form-contact" style={{ height: "110vh" }}>
      <h2>Editar Slide {slide.order}</h2>
      <form className="clearfix" noValidate>
        <div className="">
          <div className="field" style={{ marginBottom: "5px" }}>
            {/* <label htmlFor="firstSlideimage">Elegir imagen:</label> */}
            {!file && image ? (
              <div className="field">
                <label htmlFor="title">
                  <span className="font-weight-bold text-dark">
                    Imagen Actual
                  </span>
                  <img
                    src={image}
                    alt="imagen de entrada"
                    className="img-entry"
                  />
                </label>
              </div>
            ) : null}
            <ImageField file={file} onChange={readFile} />
          </div>

          <div className="field">
            <span className="font-weight-bold text-dark">Título slide</span>

            <input
              type="text"
              name="bienvenida"
              value={bienvenida}
              placeholder="Título slide"
              onChange={handleChange}
            />
          </div>

          <div className="field">
            <span className="font-weight-bold text-dark">Texto del Slide</span>

            <input
              type="text"
              name="text"
              value={text}
              placeholder="Texto del Slide"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="inline clearfix">
          <button className="btn-primary" onClick={onEditClicked}>
            Editar
          </button>
          {componente}
        </div>
      </form>
    </div>
  );
};

export default Slideform