import React from 'react'
import {NavLink} from 'react-router-dom'

function Menu1() {
    return (
        <div>
            <h3>Nuestra Organización</h3>
				<ul>
					<li><NavLink onClick={()=>{ window.scrollTo(0, 0)}} to={'/nosotros'}>Sobre Nosotros</NavLink></li>
					<li><NavLink onClick={()=>{ window.scrollTo(0, 0)}} to={'/nosotros'}>Contacto</NavLink></li>
                    <li><NavLink onClick={()=>{ window.scrollTo(0, 0)}} to={'/ayuda/apadrinazgo'}>Formas de Ayudar</NavLink></li>									
				</ul>
        </div>
    )
}

export default Menu1
