import React from 'react';
import { Link } from 'react-router-dom';
const PageTitle = () => {
    return ( 
      <div
      className="page-title"
      style={{
        background: `url(${process.env.PUBLIC_URL}/images/ONG5.jpg)`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
        <div className="container">
          <h1>Recuperar Contraseña</h1>
        </div>
      </div>
     );
}
 
export default PageTitle;