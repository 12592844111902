import React, {useState, useEffect} from "react";
import { connect, useSelector,useDispatch } from 'react-redux';
import WelcomeText from "./WelcomeText";
import ActivitiesList from "./ActivitiesList";
import Slider from "../slider/slider";
import HowToHelp from "./ayuda/HowToHelp";
import { motion } from "framer-motion";
import newsActions from '../../actions/newsActions';
import Spinner from '../spinner/Spinner';

import Carousel from '../testimonials/Carousel';

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const Home = () => {
  const dispatch = useDispatch();
  const setNewsDetail = async (id) => {
    dispatch(await newsActions.getNovelty(id))
  }
  const  loadOk  = useSelector(state => state.slideReducer.load )
  const [load, loadSlider] = useState(true);



    setTimeout(() => {
      if(loadOk){
        loadSlider(false);
      }
    }, 2000);




  const componente = (load) ? <Spinner /> : <Slider />
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition}
      className="home"
    >
      { 
        componente
      }
      <div className="container">
        <WelcomeText />
        <ActivitiesList
         setNewsDetail={setNewsDetail}
        />
      </div>
      <HowToHelp />
      <div className="container text-center">
        <Carousel />
      </div>

    </motion.div>
  );
}

const mapStateToProps = (state) => {
  return {
    news: state.newsReducer,
    testimonials: state.testimonyReducer
  }
}

export default connect(mapStateToProps, newsActions.get())(Home);
