import React, {useState} from "react";
import { Link, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import authActions from '../../actions/authActions';
import {useFormik} from 'formik';
import * as Yup from 'yup';


const FormRegister = ({history}) => {
  const dispatch = useDispatch();

  const [error, saveError] = useState(false);

//Form and validation with formik and yup
const formik = useFormik({
  initialValues:{
    name:'',
    lastname:'',
    email:'',
    password:''
  },

  validationSchema:Yup.object({
    name: Yup.string().required('El nombre no puede estar vacio').min(4,'Debe tener al menos 4 caracteres'),
    lastname: Yup.string().required('El apellido no puede estar vacio').min(4,'Debe tener al menos 4 caracteres'),
    email: Yup.string().email('El email no es valido').required('El email no puede estar vacio'),
    password: Yup.string().required('El password no puede estar vacio').min(6,'El password debe tener al menos 6 caracteres')
  }),

  onSubmit: async data =>{
    //console.log(data)
    const user = {
      firstName: data.name,
      lastName: data.lastname,
      email: data.email,
      password: data.password
    }
    //Crear usuario y redirigir a home
    try {
      dispatch(await authActions.register(user));
      history.push('/');
      saveError(false);
    } catch (error) {
      console.log(error.message);
      //MOSTRAR ALERT
      saveError(true);
      //Oculta la alerta despues de 5 segundos
      setTimeout(()=>{
        saveError(false);
      }, 5000);
    }
  }
});



  return (
    <div className="form-login form-register">
      <h2>Registrate Gratis</h2>

      <form 
        id="registerForm" 
        className="clearfix"
        onSubmit={formik.handleSubmit}
        >

        <div className="field">
          <input 
            type="text" 
            name="name" 
            placeholder="Ingresa tu Nombre" 
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
        
        {formik.touched.name && formik.errors.name ? (
          <small className="form-text alert-danger text-center">{formik.errors.name}</small>
        ) : null}
        </div>

        <div className="field">
          <input 
            type="text" 
            name="lastname" 
            placeholder="Ingresa tu Apellido"
            value={formik.values.lastname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
   
          {formik.touched.lastname && formik.errors.lastname ? (
            <small className="form-text alert-danger text-center">{formik.errors.lastname}</small>
          ) : null}
        </div>

        <div className="field">
          <input 
            type="email" 
            name="email" 
            placeholder="Ingresa tu email" 
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
          {formik.touched.email && formik.errors.email ? (
            <small className="form-text alert-danger text-center">{formik.errors.email}</small>
          ) : null}
          

        </div>

        <div className="field">
          <input 
            type="password" 
            name="password" 
            placeholder="Ingresa tu contraseña" 
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
          {formik.touched.password && formik.errors.password ? (
            <small className="form-text alert-danger text-center">{formik.errors.password}</small>
          ) : null}
        </div>
        <div className="inline clearfix">
          <button 
            type="submit" 
            value="Crear Cuenta" 
            className="btn-primary"
            style={{cursor: "pointer"}}  
          >
            Crear Cuenta</button>
          <p>Ya tenes una cuenta? <Link to="/login" >Iniciar Sesión</Link></p>
        </div>
        {error ? <p className="alert alert-danger p-2 mt-4  text-center">Hubo un error, email ya registrado</p> : null}
      </form>
    </div>
  );
};

export default withRouter(FormRegister);
