import React from 'react';
import Error404 from './404';
import AccountBar from './AccountBar';
import AccountContent from './AccountContent';
import { useSelector } from 'react-redux';

const Backoffice = () => {
    let isLogged = useSelector(state => state.authReducer.isLogged);
    return (
        <div className="account-wrapper mt-5">
            <div className="container">
                {
                    !isLogged ?
                        <Error404 />
                        :
                        <div className="row">
                            <AccountBar />
                            <AccountContent />
                        </div>
                }
            </div>
        </div>
    )
}

export default Backoffice