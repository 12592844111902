import React from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment';

const DetailNovely = () => {
    const oneNews = useSelector(state => state.newsReducer.novelty)

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 mt-5 mb-5 align-center text-center">
                    <div className="campaign-item mb-4">

                        <img className="mt-4 img-news-detail" src={oneNews.image} alt="foto" />

                        <div className="campaign-box">
                            <h3>
                                {oneNews.title}

                            </h3>
                            <div className="">
                                <div dangerouslySetInnerHTML={{ __html: oneNews.content }} />
                                <i className="fa fa-calendar-check-o" aria-hidden="true"></i><a href="#">{Moment(oneNews.createdAt).format("DD/MM/YYYY")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailNovely;