import React from 'react'
import Slide from './slide'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import slidesAction from '../../actions/slidesAction';
 

  

const Slider = (props) => {
  const dispatch = useDispatch()
  const  state  = useSelector(state => state.slideReducer )
  
  const slides = state.slides

  const  renderLiDataTarget = (list) => {
    return _.map(list, slide => {      
      const index = list.indexOf(slide)
      return <li key={`${slide.text}`} data-target="#slider" className={index == 0 ? "active" : null} data-slide-to={index}></li>
    })
  }
  const renderInnerItems = (list) => {
    return _.map(list, (slide) => {
      const index = list.indexOf(slide)
      return (
        <Slide
          key={slide.text}
          image={slide.image}
          text={slide.text}
          isFirst={index == 0}
          bienvenida={slide.bienvenida}
        />
      );
    })
  }

  
  
    //const {slides} = this.props
    return (
    <div id="slider" className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        {
          renderLiDataTarget(slides)
        }
      </ol>
      <div className="carousel-inner">
        {
          renderInnerItems(slides)
        }
    </div>
    <a className="carousel-control-prev" href="#slider" role="button" data-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="sr-only">Previous</span>
    </a>
    <a className="carousel-control-next" href="#slider" role="button" data-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="sr-only">Next</span>
    </a>
  </div>
    )
  
  }

export default Slider