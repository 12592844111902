import {
  START_LOADING_EVENTS,
  LOADING_EVENTS_SUCCESS,
  LOADING_EVENTS_ERROR
} from '../actions/types';

import apiService from '../api/events';
import Swal from 'sweetalert2';


const get = () => async dispatch =>{
  dispatch({
      type:START_LOADING_EVENTS,
      payload:true
  });


  try {
      const response = await apiService.get()
      dispatch({
          type:LOADING_EVENTS_SUCCESS,
          payload:response.events
      })
  } catch (error) {
      dispatch({
          type:LOADING_EVENTS_ERROR,
          payload:true
      })

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Hubo un error!'
      })

  }
}






export default{
  get
}