import React from 'react';
import { connect } from 'react-redux';
import ListEntries from './ListEntries';
import CreateEntry from './CreateEntry';
import EditEntry from './EditEntry';
import { motion } from "framer-motion";
import entries from '../../../actions/entriesActions';

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const AdminEntries = () => {
  // const entries = useSelector(state => state.entriesReducer.entries)
  const [showCreate, setShowCreate] = React.useState(false);
  const showCreateForm = (val) => {
    setShowCreate(val)
  }
  const [showEdit, setShowEdit] = React.useState(false);
  const [id, setId] = React.useState(null);
    const showEditForm = (val) => {
    setShowEdit(val.show)
    setId(val.id)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition}
      className="account-wrapper"
    >
      {showCreate ?
        <CreateEntry showCreateForm={showCreateForm} />
        :
        !showEdit ?
        <ListEntries showEditForm={showEditForm} showCreateForm={showCreateForm} />
        : null
      }
      {showEdit ?
        <EditEntry showEditForm={showEditForm} id={id} />
        : null
      }
    </motion.div>
  )
};

const mapStateToProps = (state) => {
  return {
    entries: state.entriesReducer
  }
}

export default connect(mapStateToProps, entries.list())(AdminEntries);