import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

const FormRecovery = ({handleSubmitForm}) => {

    //Form and validation with formik and yup
    const formik = useFormik({
      initialValues:{
        email:'',
      },
    
      validationSchema:Yup.object({
        email: Yup.string().email('El email no es valido').required('El email no puede estar vacio'),
      }),
      onSubmit: async data =>{
        try {
          await handleSubmitForm(data)
          Swal.fire(
            'Se ha enviado la solicitud con exito',
            'En caso de existir el mail, recibirá un link para cambiar la contraseña.',
            'success'
        )
        } catch(error) {

        }
        
      }
    });
    
      return (
        <div className="form-login form-register">
          <h2>Recuperar Contraseña</h2>
    
          <form 
            id="registerForm" 
            className="clearfix"
            onSubmit={formik.handleSubmit}
            >

            <div className="field">
              <input 
                type="email" 
                name="email" 
                placeholder="Ingresa tu email" 
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
              {formik.touched.email && formik.errors.email ? (
                <small className="form-text alert-danger text-center">{formik.errors.email}</small>
              ) : null}
              
            </div>
    
            <div className="inline clearfix">
              <button 
                type="submit" 
                value="Recuperar" 
                className="btn-primary">
                Recuperar</button>
            </div>
          </form>
        </div>
      );
    };
 
export default FormRecovery;