import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import { connect } from 'react-redux';
class Navbar extends Component {
  render() {
    const { isLogged, roleId } = this.props;
    return (
      <nav className="main-menu">
        <button className="c-hamburger c-hamburger--htx">
          <span></span>
        </button>
        <ul>
          <li>
            <NavLink className="kalam" to="/">
              Inicio<i className="fa fa-caret-down" aria-hidden="true"></i>
            </NavLink>
          </li>
          <li>
            <NavLink className="kalam" to="/nosotros">
              Nosotros
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
            </NavLink>
          </li>
          <li>
            <NavLink className="kalam" to="/ayuda/apadrinazgo">
              Formas de ayudar
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
            </NavLink>
            <ul className="sub-menu">
              <li>
                <Link to="/ayuda/apadrinazgo">Madrinas/Padrinos</Link>
              </li>
              <li>
                <Link to="/ayuda/donaciones">Donaciones</Link>
              </li>
              <li>
                <Link to="/ayuda/regalos">Regalos</Link>
              </li>
              {/* <li>
                <Link to="/ayuda/servicios">Servicios</Link>
              </li> */}
            </ul>
          </li>
          <li>
            <NavLink className="kalam" to="/eventos">
              Eventos<i className="fa fa-caret-down" aria-hidden="true"></i>
            </NavLink>
          </li>
          <li>
            <NavLink className="kalam" to="/novedades">
              Novedades
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
            </NavLink>
          </li>
          {isLogged ? (
            <li>
              <NavLink className="kalam" to="/escritorio/perfil">
                Escritorio
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
              </NavLink>
              {roleId === 1 ?
                <ul className="sub-menu">
                  <li><NavLink className="kalam" to="/escritorio/perfil">Perfil</NavLink></li>
                  <li><NavLink className="kalam" to="/escritorio/datos-organizacion">Editar datos organización</NavLink></li>
                  <li><NavLink className="kalam" to="/escritorio/datos-home">Editar datos página de inicio</NavLink></li>
                  <li><NavLink className="kalam" to="/escritorio/administrar-entradas">Administrar entradas</NavLink></li>
                  <li><NavLink className="kalam" to="/escritorio/administrar-testimonios">Administrar testimonios</NavLink></li>
                  <li><NavLink className="kalam" to="/escritorio/contribuciones">Ver contribuciones</NavLink></li>
                </ul> :
                <ul className="sub-menu">
                  <li><NavLink className="kalam" to="/escritorio/perfil">Perfil</NavLink></li>
                </ul>
              }
            </li>
          ) : null}
        </ul>
      </nav>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLogged: state.authReducer.isLogged,
    roleId: state.authReducer.roleId
  }
}
export default connect(mapStateToProps)(Navbar)