import {
    GET_TESTIMONIALS,
    DELETE_TESTIMONY_SUCCESS,
    DELETE_TESTIMONY_ERROR
} from './types';
import testimonialsApiService from '../api/testimony';
import Swal from 'sweetalert2';

const getAll = () => async dispatch => {
    
    try {
        const response = await testimonialsApiService.get();
        dispatch({
            type:GET_TESTIMONIALS,
            payload: response.testimony
        });
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Hubo un error al cargar los Testimonios en el Home'
        })
    }
}

const remove = (id) => async dispatch =>{
    try {
        await testimonialsApiService.remove(id);
        dispatch({
            type:DELETE_TESTIMONY_SUCCESS,
            payload:id
        });
        Swal.fire(
            'Eliminado!',
            'Tu testimonio fue eliminado',
            'success'
          )
    } catch (error) {
        console.log(error);
        dispatch({
            type:DELETE_TESTIMONY_ERROR,
            payload:error
        })       
    }
}

export default {
    getAll,
    remove
}