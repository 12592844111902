import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import ImageField from "./ImageField";
import CKEditor from "ckeditor4-react";
import entriesApiService from '../../../api/entries';
import Swal from 'sweetalert2';
import Spinner from '../../spinner/Spinner';

const CreateEntry = ({ history }) => {
  //Categories
  const options = [
    {
      id: 1,
      option: 'Novedades'
    },
    {
      id: 2,
      option: 'Eventos'
    }
  ]

  //States
  const [newEntry, setNewEntry] = useState({
    title: '',
    content: '',
    category: ''
  });
  const { title, content, category } = newEntry;
  const [file, setFile] = useState('');
  const [error, setError] = useState(false);
  const [load, loadSpinner] = useState(false);

  const handleChange = (e) => {
    setNewEntry({
      ...newEntry,
      [e.target.name]: e.target.value,
    });
  };

  const readFile = (e) => {
    setFile(e.target.files[0]);
  };

  
  const mostrarSpinner = ()=>{
    loadSpinner(true);

    setTimeout(()=>{
        loadSpinner(false);
    }, 2000);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    //Validate fields
    if (
      title.trim() === '' ||
      content.trim() === '' ||
      category.trim() === '' ||
      !file
    ) {
      setError(true);
    }
    setTimeout(() => {
      setError(false);
    }, 3000);

    if(!error){
      mostrarSpinner();
    }
    //Formdata created

    const formData = new FormData();
    formData.append("title", newEntry.title);
    formData.append("content", newEntry.content);
    formData.append("category", newEntry.category);
    formData.append("media", file);

    try {
      
      setTimeout( async () => {
        await entriesApiService.post(formData);
      Swal.fire(
        'Creada',
        'Entrada creada correctamente',
        'success'
      )
      history.push('/escritorio/administrar-entradas');
      }, 2000);
      
    } catch (error) {
      console.log(error);
    }
    //TODO: send to api here
  };

   
    //Mostra spinner 
    const componente = (load) ? <Spinner /> :  null


  return (
    <div className="start-form">
      <form onSubmit={handleSubmit}>
        {/*<!-- Imagen -->*/}
        <ImageField file={file} onChange={readFile} />

        {error ? (
          <small className="form-text alert-danger text-center">
            Todos los campos son obligatorios
          </small>
        ) : null}

        {/*<!-- Titulo -->*/}
        <div className="field">
          <label htmlFor="title">
            <span className="font-weight-bold text-dark">Titulo</span>
          </label>
          <input
            type="text"
            name="title"
            value={title}
            onChange={handleChange}
          />
          {error ? (
            <small className="form-text alert-danger text-center">
              Todos los campos son obligatorios
            </small>
          ) : null}
        </div>

        {/*<!-- Contenido -->*/}
        <div className="field">
          <label htmlFor="content">
            <span className="font-weight-bold text-dark">Contenido</span>
          </label>
          <CKEditor
            data={content}
            onChange={(e) => {
              setNewEntry({
                ...newEntry,
                content: e.editor.getData(),
              });
            }}
          />
          {error ? (
            <small className="form-text alert-danger text-center">
              Todos los campos son obligatorios
            </small>
          ) : null}
        </div>

        {/*<!-- Categorias -->*/}
        <div className="field">
          <label htmlFor="field-cat">
            <span className="font-weight-bold text-dark">Categorias</span>
            <span>*</span>
          </label>
          <div className="field-select field-cat">
            <select
              id="field-cat"
              name="category"
              onChange={handleChange}>
              <option value={category}>Seleccionar Categoria</option>
              {options.map(item => (
                <option value={item.option}>{item.option}</option>
              ))}
            </select>
            {error ? (
              <small className="form-text alert-danger text-center">
                Todos los campos son obligatorios
              </small>
            ) : null}
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row">
          <input type="submit" className="btn btn-primary" value="Crear" />
            {componente}
        </div>
      </form>
    </div>
  );
};

export default withRouter(CreateEntry);