import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const AccountBarAdmin = () => {
  let roleId = useSelector(state => state.authReducer.roleId);
  return (
    <div className="col-lg-3">
      <nav className="account-bar">
        <ul>
          <li className="active">
            <NavLink to="/escritorio/perfil">
              Escritorio
            </NavLink>
          </li>
          <li>
            <NavLink to="/escritorio/perfil">
              Perfil
            </NavLink>
          </li>
          {roleId === 1 ? (
            <ul>
              <li>
                <NavLink to="/escritorio/datos-organizacion">
                  Editar datos organización
                </NavLink>
              </li>
              <li>
                <NavLink to="/escritorio/datos-home">
                  Editar datos página inicio
                </NavLink>
              </li>
              <li>
                <NavLink to="/escritorio/administrar-entradas">
                  Administrar entradas
                </NavLink>
              </li>
              <li>
                <NavLink to="/escritorio/administrar-testimonios">
                  Administrar testimonios
                </NavLink>
              </li>
              <li>
                <NavLink to="/escritorio/contribuciones">
                  Ver contribuciones
                </NavLink>
              </li>
            </ul>
          ) : null}
        </ul>
      </nav>
    </div>
  );
};

export default AccountBarAdmin;
