import React from "react";
import Swal from 'sweetalert2';

const Testimonials = ({ item }) => {

  const { content, id, image, name } = item;

  let limit = 140;
  let textToShow = content.slice(0, limit);

  const readMore = () => {
    /*NO TOCAR LINEA 18 PORQUE FUNCIONA!!!!*/
    Swal.fire({
      imageUrl: `${image}`,
      html: `<br><h1 className="font-weight-bold mb-4">${name}</h1>
      <br>
      <p className="dark-grey-text w-responsive mx-auto mb-5">
      <div dangerouslySetInnerHTML={ __html:${content}</div> 
      </p>
      `
    })
  }

  return (

    <section className={id === 1 ? `col-md-4 col-12 carousel-item active` : ` col-md-4 col-12 carousel-item`} >
      <h4 className="font-weight-bold mb-4">{name}</h4>
      <img src={image} alt="imagen testimonio" className="rounded-circle w-50 img-testimonial img-entry" />
      <p className="dark-grey-text w-responsive mx-auto mb-5">
        <div dangerouslySetInnerHTML={{ __html: textToShow }} />
      </p>
      <button
        id="more"
        className="btn btn-primary"
        style={{ cursor: 'pointer' }}
        onClick={() => readMore()}
      >Leer más</button>
    </section>

  );
};

export default Testimonials;
