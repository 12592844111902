import React from 'react'
import {Link} from 'react-router-dom'
const Menu2 = () => {
    return (
        <div>
            <h3>Areas</h3>
				 <ul>
				 	<li><Link onClick={()=>{ window.scrollTo(0, 0)}} to={'/eventos'}>Eventos</Link></li>
					 <li><Link onClick={()=>{ window.scrollTo(0, 0)}} to={'/novedades'}>Novedades</Link></li>


				 </ul>
        </div>
    )
}

export default Menu2
