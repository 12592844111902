import React, { useEffect } from "react";
import Author from "./Author";
import Informations from "./Informations";
import Actions from "./Actions";
import { motion } from "framer-motion";
import EditProfile from "./EditProfile";

//redux
import { useSelector, useDispatch } from 'react-redux';
import authentication from '../../../actions/authActions';

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const Profile = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    const getUser = () => dispatch(authentication.userAuthenticatedAction());
    getUser();
  }, []);

  const email = useSelector(state => state.authReducer.email);
  const firstName = useSelector(state => state.authReducer.firstName);
  const lastName = useSelector(state => state.authReducer.lastName);
  const id = useSelector(state => state.authReducer.id);

  const userInfo = {
    id,
    firstName,
    lastName,
    email
  }
  const [showEdit, setShowEdit] = React.useState(false);
  const showForm = (val) => {
    setShowEdit(val)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition}
      className="account-wrapper"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="account-content profile">
              <h3 className="account-title">Mi Perfil</h3>
              <div className="account-main">
                {showEdit ?
                  <div className="account-main">
                    <EditProfile showForm={showForm} />
                  </div>
                  : <div className="account-main">
                    <Author
                      userInfo={userInfo}
                    />
                    <Informations
                      userInfo={userInfo}
                    />
                    <Actions
                      userInfo={userInfo}
                      showForm={showForm}
                    />
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Profile;
