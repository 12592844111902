import React, { useState } from 'react'
import Formik, { useFormik } from 'formik'
import * as yup from 'yup';

const FormChangePassword = ({handleChangePassword}) => {
  const [error, saveError] = useState(false);
  const formik = useFormik({
    initialValues:{
      password: '',
      confirmPassword: ''
    },
  
    validationSchema:yup.object({
      password: yup
        .string()
        .label('Nueva contraseña')
        .required()
        .min(6, 'La contraseña es muy corta.')
        .max(20, 'La contraseña es muy larga.'),
      confirmPassword: yup
        .string()
        .required()
        .label('Confirmar password')
        .test('passwords-match', 'Las contraseñas tienen que ser iguales.', function(value) {
      return this.parent.password === value;
      }),
    }),
    onSubmit: async data =>{
      try {
         await handleChangePassword({password: data.password})
      } catch(error) {
        saveError(true);
      //Oculta la alerta despues de 5 segundos
        setTimeout(()=>{
          saveError(false);
        }, 5000);
      }
      
    }
  })
  return <div className="form-login form-register">
  <h2>Recuperar Contraseña</h2>

  <form 
    id="changePasswordForm" 
    className="clearfix"
    onSubmit={formik.handleSubmit}
    >

    <div className="field">
      <input 
        type="password" 
        name="password" 
        placeholder="Ingresa una nueva contraseña" 
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        />
      {formik.touched.password && formik.errors.password ? (
        <small className="form-text alert-danger text-center">{formik.errors.password}</small>
      ) : null}
      
    </div>
    <div className="field">
      <input 
        type="password" 
        name="confirmPassword" 
        placeholder="Confirma la nueva contraseña" 
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        />
      {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
        <small className="form-text alert-danger text-center">{formik.errors.confirmPassword}</small>
      ) : null}
      
    </div>
    <div className="inline clearfix">
      <button 
        type="submit" 
        value="Recuperar" 
        className="btn-primary">
        Recuperar</button>
    </div>
  </form>
</div>
}
export default FormChangePassword