import React, {useState} from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import authActions from '../../../actions/authActions';
import Spinner from '../../spinner/Spinner';

const EditProfile = (props) => {
    let user = useSelector(state => state.authReducer);
    const dispatch = useDispatch();

    const [load, loadSpinner] = useState(false);

    const mostrarSpinner = ()=>{
        loadSpinner(true);
  
        setTimeout(()=>{
            loadSpinner(false);
        }, 2000);
    };

    //Mostra spinner 
    const componente = (load) ? <Spinner /> :  null
  
    const formik = useFormik({
        initialValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('El nombre no puede estar vacio').min(3, 'Debe tener al menos 3 caracteres'),
            lastName: Yup.string().required('El apellido no puede estar vacio').min(3, 'Debe tener al menos 3 caracteres')
        }),
        onSubmit: async data => {

            mostrarSpinner();

            setTimeout(async() => {
                
                dispatch(await authActions.update({
                    firstName: data.firstName,
                    lastName: data.lastName
                }))
                Swal.fire(
                    'Modificado!',
                    'Tus datos fueron modificados',
                    'success'
                )
                props.showForm(false)

            }, 2000);

            
        }
    });
    return (
        <div className="profile-box">
            <h3>Editar datos del perfil</h3>
            <form
                id="editProfileForm"
                className="clearfix"
                onSubmit={formik.handleSubmit}
            >
                <div className="field">
                    <input
                        type="text"
                        name="firstName"
                        placeholder="Nombre"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                        <small className="form-text alert-danger text-center">{formik.errors.firstName}</small>
                    ) : null}
                </div>
                <div className="field">
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Apellido"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                        <small className="form-text alert-danger text-center">{formik.errors.lastName}</small>
                    ) : null}
                </div>
                <div className="field">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formik.values.email}
                        disabled
                    />
                </div>
                <button
                    type="submit"
                    value="Send Messager"
                    className="btn-primary"
                    style={{ cursor: 'pointer' }}
                >Enviar
                </button>
                {componente}
            </form>
        </div>
    );
};

export default EditProfile;