const BASE_URL = 'https://brazosapi.herokuapp.com';

export default {
  LOG_IN: `${BASE_URL}/users/session/login`,
  REGISTER: `${BASE_URL}/users/`,
  MY_PROFILE: `${BASE_URL}/users/user`,
  EDIT_PROFILE: `${BASE_URL}/users`,
  ORGANIZATION: `${BASE_URL}/organization/public`,
  NEWS: `${BASE_URL}/news`,
  ONENEWS: `${BASE_URL}/news`,
  CONTRIBUTORS: `${BASE_URL}/contributors`,
  CONTACT:`${BASE_URL}/contacts/`,
  RECOVERY_REQUEST: `${BASE_URL}/auth/recover_password`,
  RECOVERY_CONFIRM: `${BASE_URL}/auth/confirm_password`,
  EVENTS: `${BASE_URL}/events`,
  ENTRIES: `${BASE_URL}/entries`,
  USERS: `${BASE_URL}/users`,
  TESTIMONIALS: `${BASE_URL}/testimonials`,
  SLIDES: `${BASE_URL}/slides`,
  EDIT_ORGANIZATION:`${BASE_URL}/organization`
};