import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class LoginBtn extends Component {
  render() {
    return (
      <div className="login login-button">
        <Link to="/login" className="btn-primary">
          Login
        </Link>
      </div>
    );
  }
}

export default LoginBtn;
