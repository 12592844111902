import ROUTES from './urls';
import base from './base';

const get = async () => {
	const result = await base.getMethod(ROUTES.TESTIMONIALS)
	return result
}

const post = async (formdata) => {
	const result = await base.postMethodFormdataAuthenticated(ROUTES.TESTIMONIALS, formdata)
	return result
}

const remove = async (id) => {
	const result = await base.deleteEntryMethod(`${ROUTES.TESTIMONIALS}/${id}`)
	return result
}

const update = async (id, formdata) => {
	const result = await base.putMethodFormdataAuthenticated(`${ROUTES.TESTIMONIALS}/${id}`, formdata)
	return result
}

export default {
	get,
	post,
	remove,
	update
}