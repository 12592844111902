import React,{useEffect} from "react";

import CardExample from "./CardExample";

//redux
import { useDispatch, useSelector } from "react-redux";
import newsActions from "../../actions/newsActions";
import eventsActions from '../../actions/eventsActions';

const GenericScreenGrid = ({urlPath}) => {

  console.log(urlPath);

  const dispatch = useDispatch();

  useEffect(() => {
    const getAllNews = () => dispatch(newsActions.get());

    const getAllEvents = () => dispatch(eventsActions.get());
    getAllNews();
    getAllEvents();
  }, []);

  const allNews = useSelector(state => state.newsReducer.news);

  const allEvents = useSelector(state => state.eventsReducer.events);

  if(urlPath !== '/novedades') return (
    <div className="grid-post">
      {allEvents.length === 0 ? (null) : 
      (
        allEvents.map((item) => <CardExample key={item.id} item={item} />)
      )}
    </div>

  );

  return (
    <div className="grid-post">
      {allNews.length === 0 ? (null) : 
      (
        allNews.map((item) => <CardExample key={item.id} item={item} />)
      )}
    </div>
  );
};

export default GenericScreenGrid;
