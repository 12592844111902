import { 
    SHOW_NEWS,
    START_LOADING_NEWS,
    LOADING_NEWS_SUCCESS,
    LOADING_NEWS_ERROR
 } from '../actions/types';


const initialState ={
    news:[],
    error: null,
    loading: false,
    deleteNews:null,
    editNews:null,
    novelty: {}
}

export default function(state = initialState,action){
    switch(action.type){

        case SHOW_NEWS:
            return {
                ...state,
                novelty: action.payload
            }
        case START_LOADING_NEWS:
            return{
                ...state,
                loading:action.payload
            }

        case LOADING_NEWS_SUCCESS:
            return{
                ...state,
                loading:false,
                error:null,
                news:action.payload
            }

        case LOADING_NEWS_ERROR:
            return{
                ...state,
                loading:false,
                error:action.payload
            }

        default:
            return state
    }
}