import React from "react";
import LoginForm from "./LoginForm";
import { LoginTitle } from "./LoginTitle";
import LoginBanner from "./LoginBanner";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const Login = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={transition}
      >
        <LoginBanner />
        <div className="form-login">
          <LoginTitle />
          <LoginForm />
          <div className="text-right">
          <NavLink to="/recovery">
              <p>Te has olvidado tu contraseña?</p>
            </NavLink>
            </div>
        </div>
      </motion.div>
    </>
  );
};

export default Login;
