import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import connect from '../../api/auth';
import Joi from 'joi';


//redux
import { useDispatch } from 'react-redux';
import {login as loginAction} from '../../actions/authActions';
import { ValidationMessage } from './ValidationMessage';

const LoginForm = ({ history }) => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [error, saveError] = useState(false);

  const onEmailChanged = (e) => {
    setEmail(e.target.value);
    setEmailErrorMessage('');
  };

  const onPasswordChanged = (e) => {
    setPassword(e.target.value);
    setPasswordErrorMessage('');
  };

  const validateLoginForm = () => {
    const schema = Joi.object().keys({
      email: Joi.string()
        .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
        .required(),
      password: Joi.string().min(6).required()
    });
    const validation = schema.validate({ email, password });
    if (validation.error) {
      const errorType = validation.error.details[0].context.label;
      if (errorType === 'email') {
        setEmailErrorMessage('Ingresar un E-mail válido.');
      }
      if (errorType === 'password') {
        setPasswordErrorMessage(
          'La contraseña debe tener como mínimo 6 carácteres'
        );
      }
    }
    return validation.error;
  };

  const onSubmit = async e => {
    e.preventDefault();

    //valido los campos
    const validationError = validateLoginForm();

    if (validationError) return;

    //Inicio Sesion
    try {
      dispatch(await loginAction({ email, password }))
      history.push('/')
      saveError(false);
    } catch (error) {
      //MOSTRAR ALERT
      saveError(true);
      //Oculta la alerta despues de 5 segundos
      setTimeout(() => {
        saveError(false);
      }, 5000);
    }
  }

  return (
    <form id="loginForm" className="clearfix" noValidate
      onSubmit={onSubmit}
    >
      <div className="field">
        <input
          type="email"
          name="email"
          value={email}
          placeholder="E-mail"
          onChange={onEmailChanged}
        />
        <ValidationMessage message={emailErrorMessage} />
      </div>
      <div className="field">
        <input
          type="password"
          name="password"
          value={password}
          placeholder="Contraseña"
          onChange={onPasswordChanged}
        />
        <ValidationMessage message={passwordErrorMessage} />
      </div>
      <div className="inline clearfix">
        <button
          type="submit"
          className="btn-primary"
          style={{cursor: "pointer"}} 
        >
          Iniciar Sesión
        </button>
        <p>
          Aún no sos miembro? <Link to="registro">Registráte</Link>
        </p>
      </div>
      {error ? <p className="alert alert-danger p-2 mt-4 text-center">Hubo un error, verifique datos ingresados</p> : null}
    </form>
  );
};

export default withRouter(LoginForm);