import React from "react";

import PageTitle from "./PageTitle";
import FormRecovery from "./FormRecovery";
import { motion } from "framer-motion";
import recoveryApi from '../../api/recover'
const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const Recovery = () => {
  const sendRequest = async (data) => {
    await recoveryApi.sendRecovery(data)
  }
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition}
      id="main"
      className="site-main"
    >
      <PageTitle />
      {/*<!-- .page-title -->*/}

      <div className="container">
        <div className="main-content">
          <FormRecovery handleSubmitForm={sendRequest} />
        </div>
      </div>
    </motion.main>
  );
};

export default Recovery;
