import React, { Component } from 'react';

import Title from './Title';
import Content from './Content';

class Help extends Component {
  render() {
    return (
      <div id="main" className="site-main">
        <Title />
        <Content />
      </div>
    );
  }
}

export default Help;
