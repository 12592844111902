import React,{useEffect} from 'react';
import Testimonials from './Testimonials';
import {useDispatch,useSelector} from 'react-redux';
import testimonialsActions from '../../actions/testimonyActions';

const Carousel = () => {

    const dispatch= useDispatch();

    useEffect(() =>{
        const getTestimonials = () => dispatch(testimonialsActions.getAll());
        getTestimonials();
    },[])

    const allTestimonials = useSelector(state => state.testimonyReducer.testimonials);
    
    return ( 

        <>
            <h2 className="h1 font-weight-bold my-5">Testimonios</h2>
            <div id="testimonials-slider" className="carousel slide carousel-fade" data-ride="carousel">
                <div className="carousel-inner d-flex justify-content-center">

                    {allTestimonials.length === 0 ? null :
                        allTestimonials.map(item =>(
                            <Testimonials
                                key={item.id}
                                item={item}
                            />
                        ))
                    }
                    
                </div>
                <a className="carousel-control-prev" href="#testimonials-slider" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon bg-dark rounded" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>

                <a className="carousel-control-next" href="#testimonials-slider" role="button" data-slide="next">
                    <span className="carousel-control-next-icon bg-dark rounded" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
            
        </>

     );
}
 
export default Carousel;
