import React from "react";


const Author = ({userInfo}) => {
  const {firstName,lastName,email} = userInfo;

  return (
    <div className="d-flex flex-column align-items-center flex-md-row author clearfix">
      <div className="author-avatar">
        <img src="/images/assets/admin.png" alt="" />
      </div>
      <div className="author-content m-auto m-md-3">
        <div className="author-title text-center">
          <h3>
            <p>{firstName} {lastName}</p>
          </h3>
        </div>
        <div className="author-info">
          <p>{email}</p>
        </div>
      </div>
    </div>
  );
};

export default Author;
