import React from 'react';

const Informations = ({ userInfo }) => {

  const { firstName, lastName, email } = userInfo;

  return (
    <div className="profile-box">
      <h3>Datos del Perfil</h3>
      <ul>
        <li>
          <strong>Nombre:</strong>
          <div className="profile-text pl-3 pl-md-auto">
            <p>{firstName}</p>
          </div>
        </li>
        <li>
          <strong>Apellido:</strong>
          <div className="profile-text pl-3 pl-md-auto">
            <p>{lastName}</p>
          </div>
        </li>
        <li>
          <strong>Email:</strong>
          <div className="profile-text pl-3 pl-md-auto">
            <p>{email}</p>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Informations;