import React, { Component, useEffect } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Logo from './Logo';
import Navbar from './Navbar';
import LoginBtn from './Login-btn';
import LogoutBtn from './Logout-btn';
import organizationAction from '../../actions/organization'
import testimonyActions from '../../actions/testimonyActions';
import contributionActions from '../../actions/contributionActions';
import * as AuthActions from '../../actions/authActions'
import slideAction from '../../actions/slidesAction';

const Header = () => {
 
    const dispatch = useDispatch()
    // const organization = useSelector(state => state.organization)
    const auth = useSelector(state => state.authReducer)
    // console.log(auth)
        // organization.get()
        useEffect(() => {
            (async () => {
                dispatch(await organizationAction.get())
                dispatch(await AuthActions.autoLogin())
                dispatch(await testimonyActions.getAll())
                dispatch(await contributionActions.getAll())
                dispatch(await slideAction.getAll())
                
            })();
          }, []);
    
        // const { isLogged } = this.props;
        return (
            <div id="wrapper">
                <header id="header" className="site-header">
                    <div className="container">
                        <Logo />
                        <div className="right-header">
                            <Navbar />
                            {auth.isLogged ? <LogoutBtn />:<LoginBtn /> }  
                        </div>                                                  
                    </div>
                </header>
            </div>
        )
    
}

export default Header