import {
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    AUTHENTICATED_USER,
    LOG_OUT,
    GET_ORGANIZATION,
    DELETE_ACCOUNT_ERROR,
    DELETE_ACCOUNT_SUCCESS,
    EDIT_PROFILE
} from './types';
import AuthApiService from '../api/auth';
import organizationService from '../api/organization';
import Swal from 'sweetalert2';

export const logout = () => async dispatch => {
    dispatch({ type: LOG_OUT })
    localStorage.clear();
}

export const login = async (credenciales) => {
    await AuthApiService.logInMethod(credenciales)
    const myinfo = await AuthApiService.myProfileMethod()
    const action = {
        type: LOGIN_SUCCESS,
        payload: {
            userName: `${myinfo.firstName} ${myinfo.lastName}`,
            id: myinfo.id,
            firstName: myinfo.firstName,
            lastName: myinfo.lastName,
            email: myinfo.email,
            roleId: myinfo.roleId
        }
    }
    return action
}

const register = async (data) => {
    const {email, password} = data;
    let action;
    try {
        await AuthApiService.registerMethod(data);
        await AuthApiService.logInMethod({email, password});
        const myinfo = await AuthApiService.myProfileMethod();
        action = {
            type: REGISTER_SUCCESS,
            payload: {
                userName: `${myinfo.firstName} ${myinfo.lastName}`,
                id: myinfo.id,
                firstName: myinfo.firstName,
                lastName: myinfo.lastName,
                email: myinfo.email,
                roleId: myinfo.roleId
            }
        }
    } catch (error) {
        console.log(error.message);
    }

    return action
}

export const autoLogin = async () => {
    try {
        const myinfo = await AuthApiService.myProfileMethod()
        const action = {
            type: LOGIN_SUCCESS,
            payload: {
                userName: `${myinfo.firstName} ${myinfo.lastName}`,
                id: myinfo.id,
                firstName: myinfo.firstName,
                lastName: myinfo.lastName,
                email: myinfo.email,
                roleId: myinfo.roleId
            }
        }
        return action
    } catch (error) {
        return { type: LOG_OUT }
    }
}

export const get = () => async dispatch => {
    const result = await organizationService.get()
    dispatch({ type: GET_ORGANIZATION, payload: result })
}
const userAuthenticatedAction = () => async dispatch => {
    //Revisa el token almacenado en localStorage para traer la infor del usuario logueado.
    const result = await AuthApiService.authMethod();
    dispatch({
        type: AUTHENTICATED_USER,
        payload: result
    })
}

const deleteAction = (id) => async dispatch => {
    try {
        await AuthApiService.deleteMethod(id);
        dispatch({
            type: DELETE_ACCOUNT_SUCCESS
        });
        Swal.fire(
            'Eliminado!',
            'Tu cuenta fue eliminada',
            'success'
        )
    } catch (error) {
        console.log(error);
        dispatch({
            type: DELETE_ACCOUNT_ERROR,
            payload: error
        })
    }
}

const update = async ( body) => {
    try {
        const myinfo = await AuthApiService.updateMethod(body)
        const result = {
            type: EDIT_PROFILE,
            payload: {
                firstName: myinfo.firstName,
                lastName: myinfo.lastName,
                email: myinfo.email
            }
        }
        return result
    } catch (error) {
        console.log(error.message)
    }
}

export default {
    userAuthenticatedAction,
    deleteAction,
    update,
    register
}
