import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion";
import FormChangePassword from './FormChangePassword'
import { useLocation } from 'react-router-dom'
import PageTitle from './PageTitle';
const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };
const RecuprarClave = () => {
  // const query = useQuery()
  // console.log(query.get('token'))
  const location = useLocation().search
  useEffect(() => {
    (async () => {
      getTokenFromLocation()
    })();
  }, []);
  const getTokenFromLocation = () => {
    try {
      const props = location.split('=')
      if (props.length != 2) {
        throw {message: 'No hay solamente 1 prop'}
      } else {
        const tokenValue = props[1]
        const key = props[0].split(0, props[0].length)
        if (key != '?token') {
          throw {message: 'Key invalida'}
        }
        setToken(tokenValue)
        setEsTokenValido(true)
      }
      
    } catch (error) {
      console.log(error.message)
    }
  }
  const [esTokenValido, setEsTokenValido] = useState(false)
  const [token, setToken] = useState('')
  const generateBody = () => {
    if (esTokenValido) {
      return <FormChangePassword handleChangePassword={(data) => handleSubmit(data)} />
    } else {
      return <p>Usted no esta entrando aca correctamente</p>
    }
  }
  const handleSubmit = (data) => {
    console.log('probando')
  }
  return <motion.main
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{ opacity: 0 }}
  transition={transition}
  id="main"
  className="site-main"
>
  <PageTitle />
{generateBody()}
</motion.main>

}
function useQuery() {
  return new URLSearchParams(useLocation().key);
}

export default RecuprarClave