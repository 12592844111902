import {
     SHOW_NEWS,
     START_LOADING_NEWS,
     LOADING_NEWS_SUCCESS,
     LOADING_NEWS_ERROR
 } from './types';

 
import Swal from 'sweetalert2';
import newsApiService from '../api/news';

const getNovelty = (id) => async dispatch =>{
    const result = await newsApiService.getOne(id);
    dispatch({ type: SHOW_NEWS, payload: result });
}

const get = () => async dispatch =>{
    dispatch({
        type:START_LOADING_NEWS,
        payload:true
    });

    try {
        const response = await newsApiService.get()
        
        dispatch({
            type:LOADING_NEWS_SUCCESS,
            payload:response.news
        })
    } catch (error) {
        dispatch({
            type:LOADING_NEWS_ERROR,
            payload:true
        })

        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Hubo un error al cargar las novedades en el Home!'
        })
         
    }
}
export default {
    getNovelty,
    get
}
