import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Spinner from '../spinner/Spinner';

//REDUX
import {useDispatch} from 'react-redux';
import contactActions from '../../actions/contactActions';

const ContactForm = () => {

    const dispatch = useDispatch();

    const sendContact = data => dispatch(contactActions.sendAction(data));

    const [load, loadSpinner] = useState(false);

    const mostrarSpinner = ()=>{
        loadSpinner(true);

        setTimeout(()=>{
            loadSpinner(false);
        }, 2000);
    };

      //Mostra spinner o resultado
  const componente = (load) ? <Spinner /> :  null
  

    const formik = useFormik({
        initialValues: {
            fullName:'',
            email:'',
            message:''
        },
        validationSchema: Yup.object({
            fullName: Yup.string().required('El nombre y apellido no pueden estar vacios').min(8, 'Debe tener al menos 8 caracteres'),
            email: Yup.string().email('El email no es valido').required('El email no puede estar vacio'),
            message: Yup.string().required('El mensaje no puede estar vacio').min(10, 'Debe tener al menos 10 caracteres')
        }),
        onSubmit: (data, {resetForm}) => {
            //action
            sendContact(data);

            resetForm({data:''});
      
            mostrarSpinner()
        }
    });

    

    return (
        <div className="form-contact">
            <h2>Contáctanos</h2>
            <form
                id="contactForm"
                className="clearfix"
                onSubmit={formik.handleSubmit}
            >
                <div className="field">
                    <input
                        type="text"
                        name="fullName"
                        placeholder="Nombre y Apellido"
                        value={formik.values.fullName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.fullName && formik.errors.fullName ? (
                        <small className="form-text alert-danger text-center">{formik.errors.fullName}</small>
                    ) : null}
                </div>
                <div className="field">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <small className="form-text alert-danger text-center">{formik.errors.email}</small>
                    ) : null}
                </div>
                <div className="field-textarea">
                    <textarea
                        rows="8"
                        type="textarea"
                        name="message"
                        placeholder="Mensaje"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    >
                    </textarea>
                    {formik.touched.message && formik.errors.message ? (
                        <small className="form-text alert-danger text-center">{formik.errors.message}</small>
                    ) : null}
                </div>
                
                <button
                    type="submit"
                    value="Send Messager"
                    className="btn-primary"
                    style={{cursor: "pointer"}} 
                >
                    Enviar
                </button>
                {componente}
            </form>
        </div>
    )
}

export default ContactForm