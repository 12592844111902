import urls from './urls'
import base from './base';


const get = async () => {
  const result = await base.getMethod(urls.ORGANIZATION)
  return result  
}

const edit = async (id,data) =>{
  const result = await base.patchMethodFormdataAuthenticated(`${urls.EDIT_ORGANIZATION}/${id}`,data);
  return result;
}


export default {get, edit}