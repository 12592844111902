import React, { Component } from 'react'

class Slide extends Component {
  render() {
    const {image, text, isFirst, bienvenida} = this.props
    return (
      <div className={isFirst ? "carousel-item active" : "carousel-item"}>
        <div className="cropped">
          <img
            className="d-block w-100 slider"
            src={image}
            alt="Second slide"
          />
          <div className="carousel-caption d-none d-md-block" style={{backgroundColor: '#3c3c3cad'}}>
            <h2 className="align-middle" style={{ color: 'white' }}>
              {text}
            </h2>
            <p className="align-middle" >{bienvenida}</p>
          </div>
        </div>
      </div>
    );
  }
}
/*
const styles = {
  image: {
    height: 300,
    width: '100%',
    
  } 
}
*/

export default Slide