import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

export const Logo = () => {
    const logoUrl= useSelector(state => state.organization.image)

    return (
        <div className="site-brand">
            <Link to="/">
                {
                    logoUrl ?
                     <img className="logo" src={logoUrl} alt="logo"/>
                     :
                      null
                }
                
            </Link>
        </div>
    )
}


export default Logo