import React from 'react';

const Title = () => {
  return (
    <div
      className="page-title"
      style={{
        background: `url(${process.env.PUBLIC_URL}/images/banner-ayuda.jpg)`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <div className="container">
        <h1 style={{ marginBottom: '2px' }}>Formas de ayudar</h1>
        <h3 style={{ color: '#fff', marginBottom: '10px' }}>
          Enterate cómo podes ayudarnos
        </h3>
      </div>
    </div>
  );
};

export default Title;
