import React from 'react';

const Godparents = () => {
  return (
    <div>
      <h1>Madrinas / Padrinos</h1>
      <p>
        Apuntamos a desarrollar <b>madrinas</b> y <b>padrinos</b> que dediquen
        tiempo al acompañamiento de las familias integralmente, desarrollando la
        escucha activa y presente para cada caso. Visitamos y acompañamos a las
        familias de forma periódica, a través de <b>visitas grupales</b>{' '}
        reducidas, donde se le da prioridad a un encuentro especial con cada
        familia.
      </p>
      <div style={{ maxHeight: '500px', overflow: 'hidden' }}>
        <img
          src={process.env.PUBLIC_URL + '/images/apadrinazgo.jpg'}
          alt="Imagen apadrinazgo"
        />
      </div>
    </div>
  );
};

export default Godparents;
