import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import HelpNavBar from './HelpNavBar';
import HelpForm from './HelpForm';
import Godparents from './Godparents';
import Donations from './Donations';
//import Services from './Services';
import Gifts from './Gifts';

class Content extends Component {
  render() {
    return (
      <div className="campaign-history">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <HelpNavBar />
              <div className="campaign-content">
                <Router>
                  <Switch>
                    <Route
                      exact
                      path="/ayuda/apadrinazgo"
                      component={Godparents}
                    ></Route>
                    <Route
                      exact
                      path="/ayuda/donaciones"
                      component={Donations}
                    ></Route>
                    <Route
                      exact
                      path="/ayuda/regalos"
                      component={Gifts}
                    ></Route>
{/*                     <Route
                      exact
                      path="/ayuda/servicios"
                      component={Services}
                    ></Route> */}
                  </Switch>
                </Router>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="support suport-campaign">
                <HelpForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
