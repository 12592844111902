import React from 'react';
import { Link } from 'react-router-dom';

const HelpNavBar = () => {
  return (
    <div className="campaign-tabs">
      <ul className="tabs-controls">
        <li>
          <Link className="godparents" to="/ayuda/apadrinazgo">
            Apadrinazgo
          </Link>
        </li>
        <li>
          <Link className="donations" to="/ayuda/donaciones">
            Donaciones
          </Link>
        </li>
        <li>
          <Link className="gifts" to="/ayuda/regalos">
            Regalos
          </Link>
        </li>
        {/* <li>
          <Link className="services" to="/ayuda/servicios">
            Servicios
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default HelpNavBar;
