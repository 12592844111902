import {
  START_LOADING_EVENTS,
  LOADING_EVENTS_SUCCESS,
  LOADING_EVENTS_ERROR
} from '../actions/types';


const initialState = {
  events: [],
  error: null,
  loading: false,
  deleteEvents: null,
  editEvents: null
}


export default function (state = initialState, action) {
  switch (action.type) {
    case START_LOADING_EVENTS:
      return {
        ...state,
        loading: action.payload
      }

    case LOADING_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        events: action.payload
      }

    case LOADING_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      return state
  }

}