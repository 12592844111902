import {
    START_LOADING_ENTRIES,
    LOADING_ENTRIES_SUCCESS,
    LOADING_ENTRIES_ERROR,
    DELETE_ENTRIES_SUCCESS,
    DELETE_ENTRIES_ERROR
} from '../actions/types';

const initialState = {
    entries: [],
    error: null,
    loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case START_LOADING_ENTRIES:
            return {
                ...state,
                loading: action.payload
            }
        case LOADING_ENTRIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                entries: action.payload.entries
            }
        case LOADING_ENTRIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_ENTRIES_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case DELETE_ENTRIES_SUCCESS:
            state.entries = state.entries.filter(p => p.id !== action.payload)
            return state
        default:
            return state;
    }
}