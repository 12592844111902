import { GET_ALL_SLIDES } from '../actions/types/';

const initialState = {
  slides:[],
  load: false
};

export default (state = initialState, action) => { 
    switch (action.type) {
        case GET_ALL_SLIDES:
        return {
          ...state,
           slides:action.payload,
           load: true
          }
        default:
             return state
      } 
    }