import React from 'react'

function Redes({facebook,instagram}) {
    return (
        <div>
            <h3>Nuestras Redes</h3>
				<ul>
					{facebook ? <li className="facebook"><a target="_Blank" href={facebook}><i className="fa fa-facebook" aria-hidden="true"></i></a></li> : null}
					{instagram ? <li className="instagram"><a target="_Blank" href={instagram}><i className="fa fa-instagram" aria-hidden="true"></i></a></li> : null}
				</ul>
        </div>
    )
}

export default Redes
