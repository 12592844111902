import React, { useState } from 'react';
import Joi from 'joi';
import axios from 'axios';
import urls from '../../api/urls';

import ValidationMessage from './ValidationMessage';

const HelpForm = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('Madrina/Padrino');
  const [message, setMessage] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [sentMessage, setSentMessage] = useState('');
  const [isSent, setIsSent] = useState(false);

  const onFullNameChanged = (e) => {
    setFullName(e.target.value);
    setFullNameError('');
  };

  const onEmailChanged = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const onTypeChanged = (e) => {
    setType(e.target.value);
  };

  const onMessageChanged = (e) => {
    setMessage(e.target.value);
  };

  const validateFields = () => {
    const schema = Joi.object().keys({
      fullName: Joi.string().required(),
      email: Joi.string()
        .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
        .required()
    });

    const validation = schema.validate({ fullName, email });

    if (validation.error) {
      const errorType = validation.error.details[0].context.label;
      if (errorType === 'fullName') {
        setFullNameError('Ingresá tu nombre');
      }
      if (errorType === 'email') {
        setEmailError('Ingresá un e-mail válido');
      }
    }
    return validation.error;
  };

  const clearFields = () => {
    setFullName('');
    setEmail('');
    setMessage('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const validationError = validateFields();
    if (!validationError) {
      sendForm();
      setIsSent(true);
      setSentMessage('Gracias por colaborar!');
      clearFields();
    }
  };

  const sendForm = async () => {
    try {
      const result = await axios.post(urls.CONTRIBUTORS, {
        fullName,
        email,
        type,
        message
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="entry-content">
      <div className="row">
        <div className="col-lg-12">
          <div className="form-contact form-contact-help">
            <h2>¿Querés contribuir? Escribinos</h2>
            <form
              noValidate
              id="contactForm"
              class="clearfix"
              onSubmit={onSubmit}
            >
              <div className="clearfix">
                <div className="field">
                  <input
                    type="text"
                    value={fullName}
                    placeholder="Nombre y Apellido"
                    onChange={onFullNameChanged}
                  />
                  <ValidationMessage message={fullNameError} />
                </div>
                <div className="field">
                  <input
                    type="email"
                    value={email}
                    placeholder="Email"
                    onChange={onEmailChanged}
                  />
                  <ValidationMessage message={emailError} />
                </div>
              </div>
              <div className="field">
                <label htmlFor="waysToHelp">¿Cómo podés ayudar?</label>
                <select name="waysToHelp" onChange={onTypeChanged} value={type}>
                  <option>Madrina/Padrino</option>
                  <option>Donacion</option>
                  <option>Regalos</option>
                  <option>Servicios</option>
                  <option>Otros</option>
                </select>
              </div>
              <div className="field-textarea">
                <textarea
                  rows="8"
                  placeholder="Mensaje"
                  value={message}
                  onChange={onMessageChanged}
                ></textarea>
              </div>
              <button
                type="submit"
                value="Send Messager"
                className="btn btn-primary"
                style={{ cursor: 'pointer' }}
              >
                Enviar
              </button>
              <ValidationMessage message={sentMessage} isSent={isSent} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpForm;
