import React, { useState } from "react";
import { useHistory, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ImageField from "./ImageField";
import CKEditor from "ckeditor4-react";
import entriesApiService from '../../../api/entries';
import Swal from 'sweetalert2';
import Spinner from '../../spinner/Spinner';

const EditEntry = (props) => {
  const history = useHistory();
  const entry = useSelector(state => state.entriesReducer.entries);
  const entryToUpdate = entry.find(key => key.id === props.id)

  //Categories
  const options = [
    {
      id: 1,
      option: 'Novedades'
    },
    {
      id: 2,
      option: 'Eventos'
    }
  ]

  //States

  const [title, setTitle] = useState(entryToUpdate.title);
  const [content, setContent] = useState(entryToUpdate.content);
  const [category, setCategory] = useState(entryToUpdate.category);
  const [file, setFile] = useState('');
  const [error, setError] = useState(false);
  const [load, loadSpinner] = useState(false);

  const readFile = (e) => {
    setFile(e.target.files[0]);
  };

  const mostrarSpinner = ()=>{
    loadSpinner(true);

    setTimeout(()=>{
        loadSpinner(false);
    }, 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //Validate fields
    if (
      title.trim() === '' ||
      content.trim() === '' ||
      category.trim() === ''
    ) {
      setError(true);
    }
    setTimeout(() => {
      setError(false);
    }, 3000);

    if(!error){
      mostrarSpinner();
    }

    //Formdata created
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("category", category);
    formData.append("media", file);

    try {
     
      setTimeout( async () => {
        await entriesApiService.update(props.id, formData);
        Swal.fire(
          'Editada',
          'Entrada editada correctamente',
          'success'
        )
        history.push('/escritorio/administrar-entradas');
      }, 2000);

    } catch (error) {
      console.log(error);
    }
  };

      //Mostra spinner 
      const componente = (load) ? <Spinner /> :  null

  return (
    <div className="start-form">
      <form onSubmit={handleSubmit}>
        {/* <!-- Imagen --> */}
        {!file ?
          <div className="field">
            <label htmlFor="title">
              <span className="font-weight-bold text-dark">Imagen Actual</span>
              <img src={entryToUpdate.image} alt="imagen de entrada" />
            </label>
          </div>
          : null
        }

        <ImageField file={file} onChange={readFile} />

        {error ? (
          <small className="form-text alert-danger text-center">
            Todos los campos son obligatorios
          </small>
        ) : null}

        {/*<!-- Titulo -->*/}
        <div className="field">
          <label htmlFor="title">
            <span className="font-weight-bold text-dark">Titulo</span>
          </label>
          <input
            type="text"
            name="title"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          {error ? (
            <small className="form-text alert-danger text-center">
              Todos los campos son obligatorios
            </small>
          ) : null}
        </div>

        {/*<!-- Contenido -->*/}
        <div className="field">
          <label htmlFor="content">
            <span className="font-weight-bold text-dark">Contenido</span>
          </label>
          <CKEditor
            data={entryToUpdate.content}
            onChange={(e) => { setContent(e.editor.getData()) }}
          />
          {error ? (
            <small className="form-text alert-danger text-center">
              Todos los campos son obligatorios
            </small>
          ) : null}
        </div>

        {/*<!-- Categorias -->*/}
        <div className="field">
          <label htmlFor="field-cat">
            <span className="font-weight-bold text-dark">Categorias</span>
            <span>*</span>
          </label>
          <div className="field-select field-cat">
            <select
              id="field-cat"
              name="category"
              onChange={e => setCategory(e.target.value)}
            >
              <option value={entryToUpdate.category}>Seleccionar Categoria</option>
              {options.map(item => (
                <option value={item.option}>{item.option}</option>
              ))}
            </select>
            {error ? (
              <small className="form-text alert-danger text-center">
                Todos los campos son obligatorios
              </small>
            ) : null}
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row">
          <input type="submit" className="btn btn-primary" value="Editar" />
              {componente}
        </div>
      </form>
    </div>
  );
};

export default withRouter(EditEntry);