import {
    GET_CONTRIBUTIONS,

} from './types';

import contributionApiService from '../api/contribution';
import Swal from 'sweetalert2';

const getAll = () => async dispatch => {
    
    try {
        const response = await contributionApiService.get();
        console.log(response);
        dispatch({
            type: GET_CONTRIBUTIONS,
            payload: response.contributions
        });
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Hubo un error al cargar los Contribuciones en el Home'
        })
    }
}


export default {
    getAll
}