import 
{
  GET_ORGANIZATION,
  EDIT_ORGANIZATION_SUCCESS,
  EDIT_ORGANIZATION_ERROR
} 
from '../actions/types/'

const initialState = {
  name: '',
  image: '',
  phone: '',
  address: '',
  description: '',
  welcomeText: '',
  facebookUrl: '#',
  instagramUrl: '#',
  linkedinUrl: '#',
  error:null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION: return Object.assign({},state,{
      name: action.payload.name,
      image: action.payload.image,
      phone: action.payload.phone,
      address: action.payload.address,
      description: action.payload.description,
      welcomeText: action.payload.welcomeText,
      facebookUrl: action.payload.facebookUrl,
      instagramUrl: action.payload.instagramUrl,
      linkedinUrl: action.payload.linkedinUrl
    } )

    case EDIT_ORGANIZATION_ERROR:
      return{
        ...state,
        error:action.payload
      }

    case EDIT_ORGANIZATION_SUCCESS:
      return{
        ...state,
        name:action.payload.name,
        image:action.payload.image
      }


    default: return state
  } 
}