import {
    GET_CONTRIBUTIONS,
  } from '../actions/types'
  
  const initialState = {
    contributions: []
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_CONTRIBUTIONS:
        return {
          ...state,
          contributions: action.payload
        }
      default:
        return state
    }
  }