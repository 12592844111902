import {
    SEND_CONTACT,
    SEND_CONTACT_ERROR
} from '../actions/types';
import contactService from '../api/contact';
import Swal from 'sweetalert2';


const sendAction = data => async dispatch => {
    try {
        const response = await contactService.post(data)
        console.log(response);
        dispatch({
            type:SEND_CONTACT,
            payload:response
        });

        Swal.fire(
            'Correcto',
            'Tu Mensaje Fue Enviado!',
            'success'
        )
   
    } catch (error) {
        dispatch({
            type:SEND_CONTACT_ERROR,
            payload:error.message
        })
        Swal.fire({
            icon: 'error',
            title: 'Uups...',
            text: 'Algo Salio Mal!',
            
          })
    }
}

export default {
    sendAction
}