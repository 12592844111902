import urls from './urls';
import base from './base';

const get = async () => {
  const result = await base.getMethod(urls.NEWS)
  return result
}

const getOne = async (id) =>{
  const result = await base.getMethod(`${urls.ONENEWS}/${id}`);
  return result;
}

// const post = async(formdata) => {
//   const result = await base.postMethodFormdataAuthenticated(urls.NEWS, formdata)
//   return post // return Post??
// }

export default {
    get,
    getOne,
    // post
}
