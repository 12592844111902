import base from './base';
import ROUTES from './urls';

const get = async () => {
  const result = await base.getMethod(ROUTES.ENTRIES)
  return result
}

const post = async(formdata) => {
  const result = await base.postMethodFormdataAuthenticated(ROUTES.ENTRIES, formdata)
  return result
}

const remove = async (id) => {
  const result = await base.deleteEntryMethod(`${ROUTES.ENTRIES}/${id}`)
  return result
}

const update = async(id, formdata) => {
  const result = await base.putMethodFormdataAuthenticated(`${ROUTES.ENTRIES}/${id}`, formdata)
  return result
}

export default {get, post, remove, update}