import React from 'react';
import { PencilIcon, TrashIcon } from '@primer/octicons-react';
import { useSelector, useDispatch } from 'react-redux';
import entriesActions from '../../../actions/entriesActions';
import Swal from 'sweetalert2';
import Moment from 'moment';

export const ListEntries = (props) => {
    const entries = useSelector(state => state.entriesReducer.entries);
    const dispatch = useDispatch();
    const createEntry = () => {
        props.showCreateForm(true)
    }
    const deleteEntry = (id) => {
        Swal.fire({
            title: '¿Estas Seguro?',
            text: "Una entrada eliminada, no se puede recuperar!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si,eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(entriesActions.remove(id))
            }
        })
    }
    const editEntry = (id) => {
        props.showEditForm({
            show: true,
            id: id
        })
    }
    return (
        <div className="account-content backed-campaigns account-table">
            <div className="text-center mt-2">
                <button
                    type="button"
                    className="btn btn-primary mb-4 mb-md-auto"
                    style={{ cursor: 'pointer' }}
                    onClick={() => createEntry()}
                >CREAR ENTRADA</button>
            </div>
            <h3 className="account-title mt-2">Lista de Entradas</h3>
            <div className="account-main">
                <table>
                    <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Título</th>
                            <th className="text-center">Categoría</th>
                            <th className="text-center">Fecha de Creación</th>
                            <th className="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            entries.map((n) => {
                                return (
                                    <tr className="text-center" key={n.id}>
                                        <td className="text-center">{n.id}</td>
                                        <td className="text-center">{n.title}</td>
                                        <td className="text-center">{n.category}</td>
                                        <td className="text-center">{Moment(n.createdAt).format("DD/MM/YYYY")}</td>
                                        <td className="btn-group">
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => editEntry(n.id)}
                                            >
                                                <PencilIcon size={16} />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-danger ml-1"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => deleteEntry(n.id)}>
                                                <TrashIcon size={16} />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default ListEntries;