import React from 'react';

const GenericScreenTitle = ({ title, subTitle }) => {
	return (
    <div
      className="page-title"
      style={{
        background: `url(${process.env.PUBLIC_URL}/images/eventos.jpg)`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <div className="container">
        <div>
          <h1 style={{ marginBottom: '2px' }}>{title}</h1>
          <h3 style={{ color: '#fff', marginBottom: '10px' }}>{subTitle}</h3>
        </div>
      </div>
    </div>
  );
};

export default GenericScreenTitle;
