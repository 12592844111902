import React from 'react'

const HelpItem = ({id, title, icon, description}) => {
  return <div className="col-lg-4">
  <div className="item-work">
<div className="item-icon"><span>{id}</span><i className={icon} aria-hidden="true"></i></div>
    <div className="item-content">
<h3 className="item-title">{title}</h3>
      <div className="item-desc"><p>{description}</p></div>
    </div>
  </div>
</div>
} 

export default HelpItem