import organizationService from '../api/organization'
import 
{ 
  GET_ORGANIZATION,
  EDIT_ORGANIZATION_ERROR,
  EDIT_ORGANIZATION_SUCCESS
} from './types'

const get = () => async dispatch => {
  const result = await organizationService.get()
  dispatch({ type: GET_ORGANIZATION, payload: result })
}

const editAction = data => async dispatch =>{

  try {

    const response = await organizationService.edit(1,data);
    console.log(data)
    console.log(response);
    dispatch({
      type:EDIT_ORGANIZATION_SUCCESS,
      payload:response
    })
    
  } catch (error) {
    console.log(error);
    dispatch({
      type:EDIT_ORGANIZATION_ERROR,
      payload:error
    })
  }
    
  
}

export default {
  get,
  editAction
}