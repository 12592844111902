import React from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment';

const ListContributions = () => {
    const contributions = useSelector(state => state.contributionReducer.contributions);
    console.log(contributions);
    return (
        <div className="account-content backed-campaigns account-table">
            <h3 className="account-title"> Lista de Contribuciones</h3>
            <div className="account-main">
                <table>
                    <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Nombre</th>
                            <th className="text-center">Email</th>
                            <th className="text-center">Tipo</th>
                            <th className="text-center">Fecha de Creación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            contributions.map((contribution) => {
                                return (
                                    <tr className="text-center" key={contribution.id}>
                                        <td className="text-center">{contribution.id}</td>
                                        <td className="text-center">{contribution.fullName}</td>
                                        <td className="text-center">{contribution.email}</td>
                                        <td className="text-center">{contribution.type}</td>
                                        <td className="text-center">{Moment(contribution.createdAt).format("DD/MM/YYYY")}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListContributions;