import React from 'react';
import * as actions from '../../actions/authActions'
import { useDispatch } from 'react-redux';

const LogoutBtn = () => {
    const dispatch = useDispatch()
    const logout = async () => {
        dispatch(await actions.logout())
    }
    return (
        <div className="login login-button">
            <a href="/" className="btn-primary" onClick={() => logout()} >Logout</a>
        </div>
    )

}

export default LogoutBtn