import React, {useEffect } from 'react';
import Navbar from './Navbar';
import OrganizationNavbar from './OrganizationNavbar';
import SocialMedia from './SocialMedia';
//redux
import {useSelector,useDispatch} from 'react-redux';
import organization from '../../actions/organization';

const Footer = () => {

	const dispatch = useDispatch();

	useEffect(() =>{
		const getOrganization = () => dispatch(organization.get());
		getOrganization();
	},[]);


	const logo = useSelector(state => state.organization.image)
	const facebook = useSelector(state => state.organization.facebookUrl);
	const instagram = useSelector(state => state.organization.instagramUrl);

    
    return (
        <div>
            <footer id="footer" className="site-footer footer">
			<div className="footer-menu">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-sm-4 col-4">
							{logo ? <img className="logo" src={logo} alt="logo"/> : null}
						</div>
						<div className="col-lg-3 col-sm-4 col-4">
							<div className="footer-menu-item">
								<Navbar />
							</div>
						</div>
						<div className="col-lg-3 col-sm-4 col-4">
							<div className="footer-menu-item">
								<OrganizationNavbar />
							</div>
						</div>
						<div className="col-lg-3 col-sm-12 col-12">
							<div className="footer-menu-item newsletter">
							  	<div className="follow">
							  		<SocialMedia 
										  facebook={facebook}
										  instagram={instagram}
									  />
							  	</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
            
			<div className="footer-copyright">
				<div className="container">
					<p className="copyright">2021 by Alkemy. All Rights Reserved.</p>
					<a href="#" className="back-top">Volver arriba<span className="ion-android-arrow-up"></span></a>
				</div>
			</div>
		</footer>
        </div>
        
    )
}

export default Footer
