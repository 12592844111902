import Axios from 'axios';
import JWTStorage from '../localstorage/jwt';

const getMethod = async (url) => {
  const headers = getHeaderWithoutAuthorization()
  const response = await Axios.get(url, { headers })
  return response.data
}

const getMethodAuthenticated = async (url) => {
  const headers = getHeaderWithAuthorization()
  const response = await Axios.get(url, { headers })
  return response.data
}

const postMethod = async (url, content) => {
  const headers = getHeaderWithoutAuthorization()
  const response = await Axios.post(url, content, { headers })
  return response.data
}

const postMethodAuthenticated = async (url, content) => {
  const headers = getHeaderWithAuthorization()
  const response = await Axios.post(url, content, { headers })
  return response.data
}

const postMethodFormdataAuthenticated = async (url, formdata) => {
  const headers = getHeaderWithAuthorizationForm()
  const response = await Axios.post(url, formdata, { headers })
  return response.data
}
const putMethodFormdataAuthenticated = async (url, formdata) => {
  const headers = getHeaderWithAuthorization()
  const response = await Axios.put(url, formdata, { headers })
  return response.data
}
const deleteUserMethod = async (url) => {
  const headers = getHeaderWithAuthorization();
  const response = await Axios.delete(url, { headers })
  return response.data
}

const deleteEntryMethod = async (url) => {
  const headers = getHeaderWithAuthorization();
  const response = await Axios.delete(url, { headers })
  return response.data
}

const patchMethodFormdataAuthenticated = async (url, formdata) => {
  const headers = getHeaderWithAuthorization()
  const response = await Axios.patch(url, formdata, { headers })
  return response.data
}



const getHeaderWithoutAuthorization = () => {
  return {
    'Content-Type': 'application/json',
  }
}

const getHeaderWithAuthorization = () => {
  return {
    'Content-Type': 'application/json',
    'Authorization': `bearer ${JWTStorage.getJWT()}`
  }
}

const getHeaderWithAuthorizationForm = () => {
  return {
    "Content-Type": "multipart/form-data",
    'Authorization': `bearer ${JWTStorage.getJWT()}`
  }
}

export default {
  getMethod,
  getMethodAuthenticated,
  postMethod,
  postMethodAuthenticated,
  postMethodFormdataAuthenticated,
  deleteUserMethod,
  putMethodFormdataAuthenticated,
  deleteEntryMethod,
  patchMethodFormdataAuthenticated
}