const getJWT = () => {
  return localStorage.getItem('jwt')
}

const setJWT = (jwt) => {
  localStorage.setItem('jwt', jwt)
}

const clearJWT = () => {
  localStorage.removeItem('jwt')
}

export default { getJWT, setJWT, clearJWT}