import {
    START_LOADING_ENTRIES,
    LOADING_ENTRIES_SUCCESS,
    LOADING_ENTRIES_ERROR,
    DELETE_ENTRIES_SUCCESS,
    DELETE_ENTRIES_ERROR
} from '../actions/types';
import entriesApiService from '../api/entries';
import Swal from 'sweetalert2';

const list = () => async dispatch => {
    dispatch({
        type:START_LOADING_ENTRIES,
        payload:true
    });
    try {
        const result = await entriesApiService.get()
        dispatch({
            type:LOADING_ENTRIES_SUCCESS,
            payload:result
        })
    } catch (error) {
        dispatch({
            type:LOADING_ENTRIES_ERROR,
            payload:true
        })
    }
}

const remove = (id) => async dispatch =>{
    try {
        await entriesApiService.remove(id);
        dispatch({
            type:DELETE_ENTRIES_SUCCESS,
            payload:id
        });
        Swal.fire(
            'Eliminado!',
            'Tu entrada fue eliminada',
            'success'
          )
    } catch (error) {
        console.log(error);
        dispatch({
            type:DELETE_ENTRIES_ERROR,
            payload:error
        })       
    }
}

export default {
    list,
    remove
}