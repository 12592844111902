import React from "react";
import Swal from 'sweetalert2';

//redux
import { useDispatch } from 'react-redux';
import authentication from '../../../actions/authActions';

const Actions = (props) => {

  const { id } = props.userInfo;

  const dispatch = useDispatch();

  const deleteAccount = id => {
    Swal.fire({
      title: '¿Estas Seguro?',
      text: "Una cuenta eliminada, no se puede recuperar!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si,eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(authentication.deleteAction(id))
      }
    })
  }

  const editProfile = () => {
    props.showForm(true)
  }
  return (
    <div className="d-flex justify-content-between flex-md-row flex-column">

      <button
        type="button"
        className="btn btn-primary mb-4 mb-md-auto"
        style={{ cursor: 'pointer' }}
        onClick={() => editProfile()}
      >Editar</button>

      <button
        type="button"
        className="btn btn-danger"
        style={{ cursor: 'pointer' }}
        onClick={() => deleteAccount(id)}
      >Eliminar Cuenta</button>
    </div>
  );
};

export default Actions;
